import React, { Component } from "react";
import Slider from "react-slick";
import styles from "../../../assets/css/landing-pages/streetiq/streetLandingSlider.module.css";
import "../../../assets/css/landing-pages/streetiq/index.css";
// imgs
import slider1 from "../../../assets/images/landing-page/streetiq/slider1.jpg";
import slider2 from "../../../assets/images/landing-page/streetiq/slider2.jpg";
import slider3 from "../../../assets/images/landing-page/streetiq/slider3.png";
import LightsInNight from '../../../assets/images/landing-page/IMG_3627.jpg'
import NuarHouse from '../../../assets/images/landing-page/streetiq/Murmur_Backpacks.jpg'
import GreenAndCozy from '../../../assets/images/landing-page/streetiq/Murmur_TableTops.jpg'

class Research_slider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
    };
    return (
      <div className="slick-slider-platform">
      <section className={styles.street_slider_cover}>
        <Slider {...settings}>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={GreenAndCozy} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={LightsInNight} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={NuarHouse} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={GreenAndCozy} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={LightsInNight} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={NuarHouse} alt="" />
            </div>
          </div>
        </Slider>
      </section>
    </div>
    );
  }
}

export default Research_slider;