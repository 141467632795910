import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/Button/Button";
import FormsLayoutsTypeSeventh from "../../../FormsLayouts/FormsLayoutsTypeSeventh/FormsLayoutsTypeSeventh";
import FormsLayoutsTypeFourth from "./../../../FormsLayouts/FormsLayoutsTypeFourth/FormsLayoutsTypeFourth";
import FormsLayoutsTypeFifth from "./../../../FormsLayouts/FormsLayoutsTypeFifth/FormsLayoutsTypeFifth";
import FormsLayoutsTypeSecond from "./../../../FormsLayouts/FormsLayoutsTypeSecond/FormsLayoutsTypeSecond";
import classes from "./Header.module.css";
const ContactUsModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={classes.contact_modal}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <FormsLayoutsTypeSecond
          contactUsModal={props.contactUsModal}
          section={props.section}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ContactUsModal;
