import React from "react";

import classes from "../../../assets/css/surveys/index.module.scss";
import Shade from "../../../assets/images/hypeIQ/Vector.png";

class HowWeHelpBrands extends React.Component {
  render() {
    ///const { width: vieport_width } = this.props;
    return (
      <section className={classes.help_brands_section}>
        <img src={Shade} alt="" className={classes.left_watermark} />
        <img src={Shade} alt="" className={classes.right_watermark} />
        <div className={classes.help_brands_container_top}>
          <div className={`${classes.alighned}`}>
            <span className={classes.text}>How Brand IQ </span>
            <div className={classes.ray_right} />
          </div>
          <div className={`${classes.alighned}`}>
            <div className={classes.ray_left} />
            <span className={classes.low_opacity_text}>works?</span>
          </div>
        </div>
        <div className={classes.help_brands_container_bottom}>
          <p className={classes.body_text}>
            There's nothing more important that researching the feedback from
            the clients about your brand. Brand IQ makes this process
            interesting and insightful. Brands have opportunity to upload
            several surveys and maintain the number of respondents that get
            these surveys, so they can see broad range of reactions from the
            users and with that, make they brand as successful as possible
          </p>
        </div>
      </section>
    );
  }
}

export default HowWeHelpBrands;
