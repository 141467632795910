import React from "react";
import classes from "./Header.module.css";
import { Nav, Navbar, Form, FormControl } from "react-bootstrap";
import { MethodLogotype, AppLogo } from "../../../../components/Icons/Icons";
import Button from "../../../../components/Button/Button";
import Socials from "../Socials/Socials";
import { Input } from "antd";

import Fade from "react-reveal/Fade";
import { Col } from "reactstrap";
import ContactUsModal from "../../HeroLayoutsTypeTenth/Header/contactUsModal";
const { Search } = Input;

const Header = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const handleInput = (data) => {};
  return (
    <section
      className={`${classes.header} ${
        props.dark ? `dark ${classes.dark}` : ""
      }`}
    >
      <Navbar collapseOnSelect expand="lg" className="p-0">
        <div className={classes.wrapper}>
          <Navbar.Brand href="/">
            {/* <MethodLogotype/>  */}
            <img
              //src={require("../../../../img/murmurLogo3.png")}
              src={require("../../../../assets/images/landing-page/streetiq/murmur-logo.svg")}
              style={{ width: 150, height: 45 }}
            />
          </Navbar.Brand>
          {/* <div className={classes.search}>
            <Search
              onSearch={(value) => console.log(value)}
              style={{ width: 150 }}
            />
          </div> */}
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="custom-toggler"
          />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link onClick={() => setModalShow(true)}>Advertisers</Nav.Link>

            <Nav.Link href="/advertise-on-car">Drivers</Nav.Link>
            <Nav.Link href="https://blog.murmurcars.com">Blog</Nav.Link>
            <Nav.Link href="https://portal.murmurads.com">Login</Nav.Link>
            {/* <Nav.Link href="#contacts">Contact Us</Nav.Link> */}
          </Nav>

          <Button
            link={true}
            href={"#"}
            classes={"primary outline simple small "}
            value={"Contact Us"}
            onClick={() => setModalShow(true)}
          />

          {/* <div className={classes.hiddenElements}>
                         <Form className="form">
                                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                            </Form>
                            <Socials/> 
                        </div> */}
        </Navbar.Collapse>
      </Navbar>
      <ContactUsModal
        contactUsModal
        dark={props.dark ? true : false}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onClick={handleInput}
      />
    </section>
  );
};

export default Header;
