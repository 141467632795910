import React from "react";

import { Container } from "reactstrap";

import { Link } from "react-router-dom";

import "../../assets/css/landing-pages/mainLanding/index.css";
import styles from "../../assets/css/landing-pages/mainLanding/main.module.css";

import MurmurLogo from "../../assets/images/landing-page/streetiq/brandIQ.png";
import MobileMenu from "../../assets/images/landing-page/mobil-menu.svg";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({
      active: this.state.active,
    });
    this.openContactUsModal()
  }
  openContactUsModal = () => {
    console.log("Clicked")
    window.location.replace("https://app.getinsightiq.com/")
    this.setState({
      ...this.state,
      contactModal: true,
    });
  };
  render() {
    return (
      <header className={styles.main_header}>
        <Container className={styles.main_header_contain}>
          <div className={styles.main_header_left}>
            <a href="#" className={styles.logo}>
              <img src={MurmurLogo} alt="" />
            </a>
          </div>
          <div
            className={
              this.state.active
                ? "main_header_center activeMenu"
                : "main_header_center"
            }
          >
            <span>
              <Link to="/">Download App</Link>
            </span>
            {/* <span>
              <Link to="/plan-and-Pricing">Plan and Pricing</Link>
            </span>
            <span>
              <Link to="/get-paid">Get Paid to Test</Link>
            </span> */}
            {/* <span>
              <Link to="/example">See and example</Link>
            </span> */}

          </div>

          <div className={`${styles.main_header_right} main_header_right d-flex align-items-center`}>
            <span className='login'>
              <a href="https://app.getinsightiq.com/">Create a Research</a>
            </span>
            <a
              className={`${styles.header_contact}`}
              onClick={this.openContactUsModal}

            >
              <span>Get free trial</span>
            </a>
            <button
              type="button"
              onClick={this.handleClick}
              className={styles.mobile_menu}
            >
              <img src={MobileMenu} alt="" />
            </button>
          </div>
        </Container>
      </header>
    );
  }
}

export default Header;
