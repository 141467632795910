import React, { useState } from "react";
import "./CustomInput.css";
import { Form, InputGroup } from "react-bootstrap";

const CustomInput = (props) => {
  const [inputs, setInput] = useState({
    email: "",
    fullName: "",
    phone: "",
    message: "",
    LoginEmail: "",
    LoginPassword: "",
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    props.onClick(name, { [name]: value });
    setInput({ ...inputs, [name]: value });
  };

  const type = props.type || "text";
  const simple = props.simple + "" || "false";
  return (
    <Form.Group
      className={`CustomInput ${props.classes} ${props.size} ${
        props.dark ? `dark` : ""
      }`}
      controlId={props.controlId}
    >
      {props.label ? <Form.Label>{props.label}</Form.Label> : null}
      {/* {props.leftIcon ? (
        //Form to Let`s get started
        <InputGroup className="leftIcon">
          <InputGroup.Prepend>
            <InputGroup.Text>{props.icon}</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type={type}
            placeholder={props.placeholder}
            name={props.name}
            required={true}
          />
        </InputGroup>
      ) : null} */}
      {/* {props.rightIcon ? (
        <InputGroup className="rightIcon">
          <Form.Control
            type={type}
            placeholder={props.placeholder}
            name={props.name}
            required={true}
          />
          <InputGroup.Prepend>
            <InputGroup.Text>{props.icon}</InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
      ) : null} */}
      {/* {simple == "false" || props.textarea ? null : (
        <Form.Control
          type={type}
          placeholder={props.placeholder}
          name={props.name}
          required={true}
          defaultValue={inputs.email}
        />
      )} */}

      {/* {props.textarea == true ? (
        <Form.Control
          as="textarea"
          rows={props.rows}
          name={props.name}
          placeholder={props.placeholder}
        />
      ) : null} */}
      {!props.contactUsModal ? (
        //Form to Let`s get started
        <InputGroup className="leftIcon">
          <InputGroup.Prepend>
            <InputGroup.Text>{props.icon}</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type={type}
            placeholder={props.placeholder}
            name={props.name}
            required={true}
            value={
              props.name === "email"
                ? inputs.email
                : null || props.fullName === "fullName"
                ? inputs.fullName
                : null || props.name == "message"
                ? inputs.message
                : null || props.name === "phone"
                ? inputs.phone
                : null || props.name === "LoginEmail"
                ? inputs.LoginEmail
                : null || props.name === "LoginPassword"
                ? inputs.LoginPassword
                : null
            }
            onChange={handleInputChange}
          />
        </InputGroup>
      ) : null}
    </Form.Group>
  );
};

export default CustomInput;
