import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Slider from "react-slick";
import styles from '../../../assets/css/landing-pages/mainLanding/main.module.css';
import "../../../assets/css/landing-pages/mainLanding/index.css";
import './index.css'

// imgs

import slider2 from "../../../assets/images/landing-page/streetiq/Murmur_Backpacks.jpg";
import slider3 from "../../../assets/images/landing-page/streetiq/Murmur_TableTops.jpg";
import Analytics from '../../../assets/images/landing-page/analytics.png'
class Diagram_Slider extends Component {
    constructor(props){
        super(props)
        this.state = {
            width:null
        }
    }
    componentDidMount(){
        let viewportWidth = window.innerWidth;
        this.setState({
            width: viewportWidth
        })
    }
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    //   margin:20
    };
    console.log(this.state.width)
    const {width} = this.state
    return (

     <div id='custom-dots'  className="slick-slider-platform">
        <section className={styles.street_slider_cover}>
          <Slider {...settings}>
            <div className={styles.street_slider_item}>
                <Row style={{width:'100vw'}}>
                    <Col  xs={`${width<800 ? '5' : width>1200 ? '3': '4'}`} className={styles.diagram_col}>
                        <div className={styles.tailored_left}>
                            <h4 className={styles.tailored_h4}> Crate Out-of-Home Ad campaign via Murmur Ad Manager </h4>
                            <p className={styles.tailored_p}>Use StreetIQ-Geospatial data for location insughts. Create Ad with Ad Manager. Measure OOH campaign in real-time</p>
                            <a className={styles.create_champ_btn}  onClick={this.props.open}>Create OOH Campaign 
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4.99103 15.0087L14.9082 5.09151" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </Col>
                    <Col   xs={`${width<800 ? '7' : '6'}`}>
                        <div className={styles.tailored_img}>
                            <img src={ Analytics } alt="" />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.street_slider_item}>
            <Row style={{width:'100vw'}}>
                    <Col xs={`${width<800 ? '5' : width>1200 ? '3': '4'}`} className={styles.diagram_col}>
                        <div className={styles.tailored_left}>
                            <h4 className={styles.tailored_h4}> You can advertise on smart LCD Backpacks.
                            </h4>
                            <p className={styles.tailored_p}>We have the smartest OOH media type in the country. Place your ads where you want.</p>
                            <a  className={styles.create_champ_btn}  onClick={this.props.open}>Create Campaign 
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4.99103 15.0087L14.9082 5.09151" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </Col>
                    <Col   xs={`${width<800 ? '7' : '6'}`}>
                        <div className={styles.tailored_img}>
                            <img src={ slider2 } alt="" />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.street_slider_item}>
            <Row style={{width:'100vw'}}>
                    <Col  xs={`${width<800 ? '5' : width>1200 ? '3': '4'}`} className={styles.diagram_col}>
                        <div className={styles.tailored_left}>
                            <h4 className={styles.tailored_h4}> Advertise inside restaurants via Murmur dual-screen table tops</h4>
                            <p className={styles.tailored_p}>Target people where they dine in.</p>
                            <a  className={styles.create_champ_btn}  onClick={this.props.open}>Create Campaign 
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4.99103 15.0087L14.9082 5.09151" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </Col>
                    <Col  xs={`${width<800 ? '7' : '6'}`}>
                        <div className={styles.tailored_img}>
                            <img src={ slider3 } alt="" />
                        </div>
                    </Col>
                </Row>
            </div>
          
          </Slider>
        </section>
      </div>
    );
  }
}

export default Diagram_Slider;