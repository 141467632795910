import React, { Component } from "react";

import { Container, Col, Row } from "reactstrap";
import {
  ArrowLeft,
  ArrowRight,
  SimpleLeftArrow,
} from "../../../components/Icons/Icons";
import Button from "../../../components/Button/Button";

import firstMockup from "../../../img/IphoneXS.png";
import secondMockup from "../../../img/WebSiteSales.png";
import firstMockupDark from "../../../img/IphoneXS-dark.png";
import secondMockupDark from "../../../img/WebSiteSales.png";

import Socials from "./Socials/Socials";
import Header from "./Header/Header";
import classes from "./HeroLayoutsTypeSixth.module.css";
import { Fade } from "react-awesome-reveal";

const titleText = "React to Audience, Drive More Sales";
const descriptionText =
  "Murmur will help you to drive more website visits, increase foot traffic to retail store and mobile app downloads. We will show ads to your audience, who is interested in your product or service.Using smart car-top billboards, you see instantly who is looking at your ads and engage directly with them on the spot. Collecting real-time data allows you dynamically retarget people via online ads";
const baseLinkText = "Schedule a Demo";

function HeroLayoutsTypeSixth(props) {
  return (
    <React.Fragment>
      <section
        className={`${classes.hero} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          {/* <Container>
            <Fade top triggerOnce={true}>
              <Header dark={props.dark ? true : false} />
            </Fade>
          </Container> */}
          <Container>
            <Row className={classes.contentRow}>
              <Col lg="12" md="12" className="position-relative">
                <Fade right delay={50} triggerOnce={true}>
                  {props.dark === true ? (
                    <>
                      {/* <img
                        src={firstMockupDark}
                        style={{ top: "80px", right: "230px", zIndex: "999" }}
                        alt=""
                        className={`${classes.firstImage} position-absolute`}
                        width="350"
                        height="697"
                      /> */}
                      <img
                        style={{ top: "-40px", right: "-800px" }}
                        src={secondMockupDark}
                        alt=""
                        className={`${classes.secondImage} position-absolute`}
                      />
                    </>
                  ) : (
                    <>
                      {/* <img
                        src={firstMockup}
                        style={{ top: "80px", right: "230px", zIndex: "999" }}
                        alt=""
                        className={`${classes.firstImage} position-absolute`}
                        width="350"
                        height="697"
                      /> */}
                      <img
                        style={{ top: "-70px", right: "-300px" }}
                        src={secondMockup}
                        alt=""
                        className={`${classes.secondImage} position-absolute`}
                        width="786"
                        height="584"
                      />
                    </>
                  )}
                </Fade>
              </Col>

              <Col lg="5" md="6" className={classes.rightColumn}>
                <Fade top delay={50} triggerOnce={true}>
                  <h1>{titleText}</h1>
                </Fade>
                <Fade top delay={100} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {descriptionText}
                  </p>
                </Fade>
                <Fade top delay={150} triggerOnce={true}>
                  <div className={classes.buttonElement}>
                    <Button
                      link={true}
                      href={"https://calendly.com/eminfaliyev91/30min"}
                      classes={"secondary large right-icon"}
                      value={baseLinkText}
                      rightIcon={<ArrowRight fill="#ffffff" />}
                    />
                  </div>
                </Fade>

                {/* <div className={classes.socials}>
                                    <Fade top delay={200} triggerOnce={true}>
                                        <Socials/>
                                    </Fade>
                                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default HeroLayoutsTypeSixth;
