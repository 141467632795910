import { Row, Col, Container } from "reactstrap";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";

import Testimonial from "./Testimonial/TestimonialsTypeThird";
import {
  Google,
  LinkedIn,
  Twitter,
  User,
} from "../../../components/Icons/Icons";

import { Avatar } from "antd";
import Slider from "./Slider/Slider";
import classes from "./TestimonialsLayoutsTypeThird.module.css";
import { testimonials } from "./testimonials";

class TestimonialsLayoutsTypeThird extends Component {
  state = {
    persons: testimonials,
  };

  renderCards() {
    const card = this.state.persons;
    const listItems = card.map((item, index) => {
      return (
        <Testimonial
          key={index}
          person={item.name}
          post={item.post}
          description={item.description}
          dark={this.props.dark ? true : false}
          link={item.link}
          avatar={
            item.avatar ? (
              <Avatar shape="square" size={445} src={item.avatar} />
            ) : (
              <Avatar
                shape="square"
                size={445}
                icon={<User fill={"#ffffff"} />}
                style={{
                  fontSize: 0,
                  backgroundColor: "#345DEE",
                }}
              />
            )
          }
        />
      );
    });
    return <Slider array={listItems} dark={this.props.dark ? true : false} />;
  }

  render() {
    return (
      <div>
        <section
          className={`section ${classes.testimonials} ${
            this.props.dark ? `dark ${classes.dark}` : ""
          }`}
        >
          <Container>
            <Row className={classes.testimonialsRow}>
              <Col className="p-0">{this.renderCards()}</Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default TestimonialsLayoutsTypeThird;
