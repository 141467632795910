import { Redirect } from "react-router-dom";
import React from "react";
import AdminLogin from "./../Admin/LoginPage/index";
import TeamLayoutsTypeFifth from "./../layouts/TeamLayouts/TeamLayoutsTypeFifth/TeamLayoutsTypeFifth";
import TeamLayoutsTypeNinth from "./../layouts/TeamLayouts/TeamLayoutsTypeNinth/TeamLayoutsTypeNinth";
import AdvertiOnCar from "./../Pages/advertiseOnCar";
import forAdvertisers from "./../PageforAdvertisers/index";


import MainLanding from '../Pages/landing-pages/main-landing'
import StreetIQ from "../Pages/landing-pages/street-iq";
import Destination_landing from "../Pages/landing-pages/destination";
import LandingPage from "../Pages/landing-pages/landing-page";
import SurveyRewards from "../Pages/landing-pages/survey-rewards";
import HypeIQ from "../Pages/hypeIQ";
import Tbilisi from '../Pages/tbilisi-istanbul/tbilisi'
import Istanbul from '../Pages/tbilisi-istanbul/istanbul'
import Backapckers from "../Pages/backpacks";
import Surveys from "../Pages/Surveys";

const PageAuthenticated = [
  // { path: "/dashboard1", component: TeamLayoutsTypeFifth },
  // { path: "/dashboard2", component: TeamLayoutsTypeNinth },
  // { path: "/", component: () => <Redirect to="/dashboard2" /> },
];

const PagePublic = [
  // { path: "/mainlanding", component: MainLanding },
  // { path: "/advertise-on-car", component: AdvertiOnCar },
  // { path: "/admin", component: AdminLogin },
  // { path: "/advertisers", component: forAdvertisers },
  //{ path: "/main", component: MainLanding},
  // { path: "/streetLanding", component: StreetIQ},
  // { path: '/destinationLanding', component: Destination_landing},
  // { path: '/landing', component: LandingPage},
  { path: '/', component: SurveyRewards},
  // { path: '/hypeIQ', component: HypeIQ},
  // { path: '/tbilisi', component: Tbilisi},
  // { path: '/istanbul', component: Istanbul},
  // { path: '/backpackers', component: Backapckers},
   { path: '/surveys', component: Surveys}
];

export { PageAuthenticated, PagePublic };