import React from "react";
import classes from "./FeaturesLayoutsTypeFirst.module.css";

import VerticalModal from "./VerticalModal/VerticalModal";
import Features from "./Features/Features";
import { Container, Col, Row } from "reactstrap";
import Button from "../../../components/Button/Button";
import { SimpleLeftArrow } from "../../../components/Icons/Icons";
import placeholder from "../../../img/placeholder-gray.png";
import placeholderDark from "../../../img/placeholder-gray-dark.png";
import Divider from "../../../components/Divider/Divider";
import { Fade } from "react-awesome-reveal";
import Image from "react-bootstrap/Image";
import PlayButton from "../../../components/Play-Button/Play-Button";
import Murmur1 from "../../../img/murmur1.gif";
import ContactUsModal from "../../HeroLayouts/HeroLayoutsTypeTenth/Header/contactUsModal";

const title = "Get in front of your customers like never before with Murmur";
//"Speak with Your Audience by Personalizing Messages via Programmatic Advertising";
const description =
  "Our real-time data shows where your ideal customers are located and hanging out right now. Connect and engage with them right on spot via Murmur`s car-top digital billboards. More new customers, higher brand value and better clickthrough rates guaranteed through Murmur’s patent-pending ad optimization platform. Build trust, brand awareness, achieve higher sales, revenue and display highly personalized ads to your audience  at any given time of day or location at the click of a few buttons.";
//"Use the most sophisticated real-time data to explore where your audience is hanging out, connect with them on streets. Using our smart digital car-top billboard technology, Computer Vision, AI/ML, engage with viewers in real-time and collect data to create retargeting ads.";
//"Murmur gets your ad across online and offline channels through highly optimized national campaigns driving home your value proposition. From  vehicle on top billboards to social media networks, Murmur places your ad where your customer is at attractive costs.Tap into unexplored markets, give your brand trust and transparency through higher reach and clickthrough rates and record higher viewability metrics all with Murmur’s patent-pending ad optimization platform.";

//const Murmur1 = "../../../img/murmur1.gif";

function FeaturesLayoutsTypeFirst(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <React.Fragment>
      <section
        className={`section ${classes.features} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg="6" md="6" className={classes.leftColumn}>
                <Fade top triggerOnce={true}>
                  <h1>{title}</h1>
                </Fade>
                <Fade top delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {description}
                  </p>
                </Fade>

                <div className={classes.playButton}>
                  <Fade top delay={100} triggerOnce={true}>
                    <Button
                      link={true}
                      href={"https://app.murmurcars.com/register"}
                      // onClick={() => setModalShow(true)}
                      target="_blank"
                      classes={`primary large right-icon ${classes.button}`}
                      value={"Get Started"}
                      rightIcon={<SimpleLeftArrow fill="#ffffff" />}
                    />
                  </Fade>
                </div>
              </Col>
              <Col
                lg="6"
                md="6"
                className={`position-relative ${classes.rightColumn}`}
              >
                <Fade top triggerOnce={true}>
                  <img
                    width="475"
                    height="300"
                    //src={props.dark === true ? placeholderDark : placeholder}
                    src={require("../../../img/Murmur Cars Advertising.png")}
                    alt=""
                  />
                  {/* <Image
                    src={require("../../../img/Murmur Cars Advertising.png")}
                    fluid
                    width="475"
                    height="380"
                  /> */}
                  {/* <img
                    width="675"
                    height="180"
                    
                    alt=" advertise on cars"
                    style={{ borderRadius: 10 }}
                  /> */}
                </Fade>
                {/* <div className={`position-absolute ${classes.buttonsSection} `}>
                  <Fade top triggerOnce={true}>
                    <PlayButton size="large" click={() => setModalShow(true)} />
                  </Fade>
                </div> */}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg="12" md="12">
                <Fade top delay={150} triggerOnce={true}>
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                </Fade>
              </Col>
            </Row>
            <Features />
            {/* <VerticalModal
              dark={props.dark ? true : false}
              show={modalShow}
              onHide={() => setModalShow(false)}
            /> */}

            <ContactUsModal
              contactUsModal
              dark={props.dark ? true : false}
              show={modalShow}
              onHide={() => setModalShow(false)}
              section="2"
            />
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}

export default FeaturesLayoutsTypeFirst;
