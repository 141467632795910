import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import Vision_bg1 from "../../assets/images/landing-page/mainlanding/vision-bg1.png";
import HypeMixedGroup from "../../assets/images/hypeIQ/hype-mixed-group.png";
import MurmurLogo from "../../assets/images/landing-page/streetiq/murmur-logo.svg";
import MobileMenu from "../../assets/images/landing-page/mobil-menu.svg";
import Shade from "../../assets/images/hypeIQ/Vector.png";
import PG from '../../assets/images/hypeIQ/P&G.svg'
import Nestle from '../../assets/images/hypeIQ/Nestle.svg'
import Kapital from '../../assets/images/hypeIQ/Kapital.svg'
import Sirab from '../../assets/images/hypeIQ/Sirab.svg'


// css
import "../../assets/css/landing-pages/mainLanding/index.css";
import styles from "../../assets/css/landing-pages/mainLanding/main.module.css";
import hypeStyles from "../../assets/css/hypeIQ/index.module.scss";

import ContactUsModal from "../../layouts/HeroLayouts/HeroLayoutsTypeTenth/Header/contactUsModal";
import { SlackWebHook } from "../../api";

import Benefits from "../../components/sections/benefits/benefits";
import GetTailoredMarketingStrategy from "../../components/sections/getTailoredMarketingStrategy/gettailoredmarketingstrategy";
import Footer from "../../components/sections/footer/footer";
import HowWeHelpBrands from "../../components/sections/howWeHelpBrands/howwehelpbrands";
import Testimonials from "../../components/landing-pages/hypeIQ/testimonials";
import System_Works_Slider from "../../components/landing-pages/hypeIQ/System-Works-Slider";

class HypeIQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      contact: {
        fullName: null,
        email: null,
        company: null,
        phone: null,
        message: null,
        companywebsite: null,
      },
      viewport: {
        width: window.innerWidth,
      },
      contactModal: false,
    };
    this.handleClick = this.handleClick.bind(this);

    this.content = [
      {
        header: "Quick application process:",
        paragraph:
          "The clients can fill out a 1-page application form within 5-6 minutes",
      },
      {
        header: "Attracting more potential clients to the brand:",
        paragraph:
          "After receiving the form, Hype IQ team starts o work on building a buzz strategy for the clients",
      },
      {
        header: "Creating a live hype around the product:",
        paragraph:
          "Via Hype IQ, our clients will be able to see how everyone in their area about their brand in no time",
      },
      {
        header: "Building strong awareness around the brand:",
        paragraph:
          "Via Hype IQ, your brand will be introduced to the public as accurately as possible",
      },
      {
        header: "Your brand will stand out:",
        paragraph:
          "In the ever-changing world market, Hype IQ will make your brand’s representation authentic from others",
      },
      {
        header: "Setting up a strong social media presence:",
        paragraph:
          "Via Hype IQ, a strong social media presence will be prepared for the clients",
      },
    ];
  }
  handleClick() {
    this.setState({
      active: !this.state.active,
    });
  }
  openContactUsModal = () => {
    this.setState({
      ...this.state,
      contactModal: true,
    });
  };
  switchToCLickedVerticalSlide = (slide) => {
    this.setState({
      ...this.state,
      slider: {
        slide,
      },
    });
  };

  updateSize = () => {
    const currentWidth = window.innerWidth;

    this.setState({
      ...this.state,
      viewport: {
        width: currentWidth,
      },
    });
  };

  componentWillUnmount() {
    document.body.classList.remove("bg-transparent");
    document.body.classList.add("default");
    window.removeEventListener("resize", this.updateSize);
  }
  componentDidMount() {
    document.body.classList.remove("default");
    document.body.classList.add("bg-transparent");
    window.addEventListener("resize", this.updateSize);
  }
  handleSubmit = () => {
    const { fullName, email, company, phone, message, companywebsite } =
      this.state.contact;
    axios
      .post(
        SlackWebHook,
        { fullName, email, company, phone, message, companywebsite },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              " GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        }
      )
      .then((res) => {
        window.location.reload();
        // passDataToParent(true);
      })
      .catch((err) => {
        //window.location.reload();
        // passDataToParent(true);
      });
  };
  emailToSend = (e, type) => {
    const value = e.target.value;

    this.setState({
      ...this.state,
      contact: { ...this.state.contact, [type]: value },
    });
  };
  render() {
    const { viewport } = this.state;
    const { width } = viewport;
    return (
        <div className={hypeStyles.hypeiq_page}> 
        <header className={styles.main_header}>
          <Container className={styles.main_header_contain}>
            <div className={hypeStyles.header_logo}>
              HypeIQ
              <span className={hypeStyles.logo_text}>
                by <img src={MurmurLogo} alt="" />
              </span>
            </div>
            <div
              className={
                this.state.active
                  ? "main_header_center activeMenu"
                  : "main_header_center"
              }
            >
              <span>
                <Link to="/advertisers">Advertisers</Link>
              </span>
              <span>
                <Link to="/advertise-on-car">Drivers</Link>
              </span>
              <span>
                <a href="https://blog.murmurcars.com">Blog</a>
              </span>
              <span>
                <a href="https://app.murmurcars.com/login">Login</a>
              </span>
            </div>
            <div className={styles.main_header_right}>
              <a
                className={styles.header_contact}
                onClick={this.openContactUsModal}
              >
                <span>Contact us</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                    fill="#7356C0"
                  />
                  <path
                    d="M12.0008 12.87C11.1608 12.87 10.3108 12.61 9.66076 12.08L6.53075 9.58C6.21075 9.32 6.15076 8.85 6.41076 8.53C6.67076 8.21 7.14076 8.15 7.46076 8.41L10.5908 10.91C11.3508 11.52 12.6407 11.52 13.4007 10.91L16.5308 8.41C16.8508 8.15 17.3308 8.2 17.5808 8.53C17.8408 8.85 17.7908 9.33 17.4608 9.58L14.3308 12.08C13.6908 12.61 12.8408 12.87 12.0008 12.87Z"
                    fill="#7356C0"
                  />
                </svg>
              </a>
              <button
                type="button"
                onClick={this.handleClick}
                className={styles.mobile_menu}
              >
                <img src={MobileMenu} alt="" />
              </button>
            </div>
          </Container>
        </header>
        <section className={hypeStyles.most_upper_container}>
          <Container className={hypeStyles.child_container}>
            <div>
              <img src={HypeMixedGroup} alt="" />

              <h1 className={hypeStyles.text}>
                <span>Create a buzz around your brand, </span>{" "}
                <span>Immediately!</span>
              </h1>
              <p className={`${hypeStyles.text} ${hypeStyles.paragraph_text}`}>
                Let everyone know about your bussines.
              </p>
            </div>
          </Container>
        </section>
        <section>
          <Container>
              <div className={hypeStyles.brands}>
                <h2>We work with some of the world’s leading FMCG, CPG, B2C brands</h2>
                <ul>
                  <li>
                    <img src={PG} alt='brands'/>
                  </li>
                  <li>
                    <img src={Nestle} alt='brands'/>
                  </li>
                  <li>
                    <img src={Kapital} alt='brands'/>
                  </li>
                  <li>
                    <img src={Sirab} alt='brands'/>
                  </li>
                </ul>
              </div>
          </Container>
        </section>
        <Benefits
          showShades={false}
          showComputerVisionPart={false}
          content={this.content}
        />
        <HowWeHelpBrands width={width} />
        <section className={styles.system_works}>
          <h2 className={styles.title_h2}>Who works with us?</h2>
          <div className={styles.main_sub_txt}>
            Our screns powered by computer vision and patented sensor technology
            to harvest first hand data and after we enrich it by partnering with
            global data providers.
          </div>
          <Testimonials />
          <img src={Vision_bg1} alt="" className={styles.vision_bg1} />
        </section>
        <section className={styles.system_works}>
          <h2 className={styles.title_h2}>How Hype IQ works?</h2>
          <div className={styles.main_sub_txt}>
          Take a look at how we make your ad campaign go viral
          </div>
          <System_Works_Slider/>
          <img src={Vision_bg1} alt="" className={styles.vision_bg1} />
        </section>
        <GetTailoredMarketingStrategy emailToSend={this.emailToSend} />

        <section
          className={`${styles.contact_section} ${styles.marginBTN_zero}`}
        >
          <img src={Shade} alt="" className={hypeStyles.left_watermark} />
          <img src={Shade} alt="" className={hypeStyles.right_watermark} />
          <Container
            className={`${styles.contact_container} ${styles.social_media_container}`}
          >
            <div className={styles.contact_cover}>
              <div className={styles.contact_inner}>
                <Row>
                  <Col md="12" xs="12">
                    <div className={styles.contact_left}>
                      <h4>Create Campaing</h4>
                      <p className={styles.contact_p}>info@murmurcars.com</p>
                      <a
                        onClick={this.openContactUsModal}
                        className={styles.create_champ_btn}
                      >
                        Create Campaign
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133"
                            stroke="#7356C0"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.99103 15.0087L14.9082 5.09151"
                            stroke="#7356C0"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className={styles.social_media} />
          </Container>
        </section>
        <Footer openContactUsModal={this.openContactUsModal} />
        <ContactUsModal
          contactUsModal
          show={this.state.contactModal}
          onHide={() => this.setState({ ...this.state, contactModal: false })}
        />
    </div>
    );
  }
}

export default HypeIQ;
