import React, { Component } from "react";
import "./Badges.css";
import { AppStore, PlayMarket } from "../Icons/Icons";

class Badge extends Component {
  render() {
    const isAppStore = this.props.store;
    const color = this.props.color || "#345dee";
    const radius = this.props.radius || "8";

    return (
      <React.Fragment>
        {isAppStore ? (
          <a
            href="https://apps.apple.com/app/id1545808872"
            className={`Badge-link ${this.props.classes}`}
            style={{
              backgroundColor: `${color}`,
              borderRadius: `${radius}px`,
            }}
          >
            <span className="left">
              <AppStore fill="#ffffff" />
            </span>
            <span className="right">
              <p>Download on the</p>
              <strong className="Badge-strong">App Store</strong>
            </span>
          </a>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Badge;
