import React, { Component } from "react";
import Slider from "react-slick";
import styles from "../../../assets/css/landing-pages/streetiq/streetLandingSlider.module.css";
import "../../../assets/css/landing-pages/streetiq/index.css";

// imgs

import ComputerVision from "../../../assets/images/landing-page/computer-vision.png";
import ProccessUnderhood from "../../../assets/images/landing-page/proccess-underhood.png";
import Analytics from "../../../assets/images/landing-page/analytics.png";

import HypeIQ from "../../../assets/images/hypeIQ/HypeIQ.png";
import Step1 from "../../../assets/images/hypeIQ/Step1.png";
import Step2 from "../../../assets/images/hypeIQ/Step2.png";
import Step3 from "../../../assets/images/hypeIQ/Step3.png";
import Influencers from '../../../assets/images/hypeIQ/Influencers.png'
import PR from '../../../assets/images/hypeIQ/Pr.png'
import Links from '../../../assets/images/hypeIQ/Links.png'
import Details from '../../../assets/images/hypeIQ/details.png'

class System_Works_Slider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      variableWidth: true,
    };
    return (
      <div className="slick-slider-platform p-0">
        <section className={styles.street_slider_cover}>
          <Slider {...settings}>
            <div className={styles.street_slider_item}>
              <div
                className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_first}`}
              >
                <h4>A frequent check-point of your ads </h4>
                <p>
                Find out how the daily hyping works 
                </p>
              </div>
              <div className={styles.slider_item_img}>
                <a href="#">
                  <img src={HypeIQ} alt="" />
                </a>
              </div>
            </div>
            <div className={styles.street_slider_item}>
              <div
                className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_hype}`}
              >
                <h4>Make your ad hyping in 1..2..3</h4>
                <p>
                Filling out the forms via Hype IQ platform is easy as ABC
                </p>
              </div>
              <div className={`${styles.slider_item_img}`}>
                <a href="#">
                  <img src={Step1} alt="" />
                </a>
              </div>
            </div>
            <div className={styles.street_slider_item}>
              <div
                className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_hype}`}
              >
                <h4> Power of Influencers</h4>
                <p>
                Take the social media world by storm with our influencers
                </p>
              </div>
              <div className={styles.slider_item_img}>
                <a href="#">
                  <img src={Influencers} alt="" />
                </a>
              </div>
            </div>
     
            <div className={styles.street_slider_item}>
              <div
                className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_hype}`}
              >
                <h4>You choose, Murmur crafts</h4>
                <p>The results of the ad campaign will be reached according to your plan </p>
              </div>
              <div className={`${styles.slider_item_img}`}>
                <a href="#">
                  <img src={PR} alt="" />
                </a>
              </div>
            </div>
      
            <div className={styles.street_slider_item}>
              <div
                className={`${styles.slider_item_txt} ${styles.slider_item_txt_max_width_main}`}
              >
                <h4>Massive hyping on all social media platforms</h4>
                <p>
                Go viral on each social media platform where your brand is presented 
                </p>
              </div>
              <div className={styles.slider_item_img}>
                <a href="#">
                  <img src={Links} alt="" />
                </a>
              </div>
            </div>
            <div className={styles.street_slider_item}>
              <div
                className={`${styles.slider_item_txt} ${styles.slider_item_txt_max_width_main}`}
              >
                <h4>You are one click away from overnight popularity</h4>
                <p>
                Confirm your hype by adding the banner/post that will go viral
                </p>
              </div>
              <div className={styles.slider_item_img}>
                <a href="#">
                  <img src={Details} alt="" />
                </a>
              </div>
            </div>
            <div className={styles.street_slider_item}>
              <div
                className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_first}`}
              >
                <h4>A frequent check-point of your ads </h4>
                <p>
                Find out how the daily hyping works 
                </p>
              </div>
              <div className={styles.slider_item_img}>
                <a href="#">
                  <img src={HypeIQ} alt="" />
                </a>
              </div>
            </div>
          </Slider>
        </section>
      </div>
    );
  }
}

export default System_Works_Slider;
