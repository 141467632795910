import React, { Component } from "react";
import Slider from "react-slick";
import styles from '../../../assets/css/landing-pages/streetiq/streetLandingSlider.module.css';
import "../../../assets/css/landing-pages/streetiq/index.css";

// imgs

import ComputerVision from '../../../assets/images/landing-page/computer-vision.png'
import ProccessUnderhood from '../../../assets/images/landing-page/proccess-underhood.png'
import Analytics from '../../../assets/images/landing-page/analytics.png'

class System_Works_Slider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
    };
    return (
        <div className="slick-slider-platform">
        <section className={styles.street_slider_cover}>
          <Slider {...settings}>
            <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_main}`}>
                    <h4>Computer vision </h4>
                    <p>Murmur's computer vision receive initial data for the advertising </p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                    <img src={ComputerVision} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_main}`}>
                    <h4>Process of data enrichment</h4>
                    <p>We analyze the received data with global data providers </p>
                </div>
                <div className={`${styles.slider_item_img}`}>
                    <a href="#">
                    <img src={ProccessUnderhood} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_max_width_main}`}>
                    <h4>Analytics</h4>
                    <p>After detailed analysis, Murmur presents ad's analytics to its customers individually </p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                    <img src={Analytics} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_main}`}>
                    <h4>Computer vision </h4>
                    <p>Murmur's computer vision receive initial data for the advertising </p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                    <img src={ComputerVision} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding_main}`}>
                    <h4>Process of data enrichment</h4>
                    <p>We analyze the received data with global data providers </p>
                </div>
                <div className={`${styles.slider_item_img}`}>
                    <a href="#">
                    <img src={ProccessUnderhood} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_max_width_main}`}>
                    <h4>Analytics</h4>
                    <p>After detailed analysis, Murmur presents ad's analytics to its customers individually </p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                    <img src={Analytics} alt="" />
                    </a>
                </div>
            </div>
          </Slider>
        </section>
      </div>
    );
  }
}

export default System_Works_Slider;