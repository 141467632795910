import React from "react";
import ReactPlayer from "react-player";
import { Container, Col, Row } from "react-bootstrap";
import classes from "../HeroLayoutsTypeTenth.module.css";

function VideoPlayer() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=wdiSbmtmL7g"
          volume="2"
        />
      </Row>
    </Container>
  );
}

export default VideoPlayer;
