import React from "react";
import Form from "./SimpleForm/SimpleForm";

import { Container, Col, Row } from "reactstrap";
import classes from "./FormsLayoutsTypeSeventh.module.css";
import { Fade } from "react-awesome-reveal";
const title =
  "Weekly Deliver of Marketing, Advertising Insights, Tips and Best Practices";
const description =
  "Discover how some of the globe’s biggest and brightest companies are hitting new highs and revenue figures, manage sales process and tap into the power of the world’s most customized marketing platforms";
const shortDescription = "Receive Case Studies & Advertising Tips";

function FormsLayoutsTypeSeventh(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.form} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.wrapper}>
          <Container>
            <Row>
              <Col lg={8} md={8} className="mx-auto text-center">
                <Fade down triggerOnce={true}>
                  <h1 className={`title ${classes.title}`}>{title}</h1>
                </Fade>
                <Fade down delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {description}
                  </p>
                </Fade>
              </Col>
            </Row>
            <Row className={classes.formsRow}>
              <Col lg={8} md={8} className="mx-auto text-center">
                <Fade down delay={100} triggerOnce={true}>
                  <Form dark={props.dark ? true : false} />
                </Fade>
              </Col>
            </Row>
            <Row className={classes.textRow}>
              <Col lg={8} md={8} className="mx-auto text-center">
                <Fade down delay={150} triggerOnce={true}>
                  <p className="normal-text ">{shortDescription}</p>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FormsLayoutsTypeSeventh;
