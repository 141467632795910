import React, { Component } from "react";

import Slider from "react-slick";

import styles from '../../../assets/css/landing-pages/streetiq/streetLandingSlider.module.css';
import "../../../assets/css/landing-pages/streetiq/index.css";

import Testimonial from "./testimonial";



class System_Works_Slider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
    };
    return (
        <div className="slick-slider-platform">
        <section className={styles.street_slider_cover}>
          <Slider {...settings}>
           
           <Testimonial/>
           <Testimonial/>
           <Testimonial/>
           <Testimonial/>
           <Testimonial/>
           <Testimonial/>
            
          </Slider>
        </section>
      </div>
    );
  }
}

export default System_Works_Slider;