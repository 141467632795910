import React from "react";

import { Col, Container, Row } from "reactstrap";

import Footer from "../../components/sections/footer/footer";
import Header from "../../components/sections/header";

//import "../../assets/css/landing-pages/mainLanding/index.css";
import styles from "../../assets/css/backpackers/main.module.scss";

import MainTop from "../../assets/images/landing-page/mainlanding/main-top.png";
import Headerbg1 from "../../assets/images/landing-page/mainlanding/header-bg1.png";
import Headerbg2 from "../../assets/images/landing-page/mainlanding/header-bg2.png";
import Backpacker from "../../assets/images/backpackers/backpacker.png";
import App from "../../assets/images/backpackers/app.png";
import Interview from "../../assets/images/backpackers/interview.png";
import Walk from "../../assets/images/backpackers/walk.png";
import Backpackers from "../../assets/images/backpackers/Backpackers.png";

class BackapckersComponent extends React.Component {
  componentWillUnmount() {
    document.body.classList.remove("bg-transparent");
    document.body.classList.add("default");
  }
  componentDidMount() {
    document.body.classList.remove("default");
    document.body.classList.add("bg-transparent");
  }
  render() {
    return (
      <React.Fragment>
        <section className={styles.main_top_section}>
          <Container className={styles.main_top_contain}>
            <Header />
            <Row>
              <Col xs="5">
                <div className={styles.tailored_left}>
                  <div>
                    <h4 className={styles.tailored_h4}>
                      Getting paid by advertising on the streets
                    </h4>
                    <p className={styles.tailored_p}>
                      It’s time to be a part of exciting journey with Murmur.
                      One of the Murmur’s core element is backpack walkers.
                      Without them, we would not be where we are now. Receiving
                      data from the streets to the advertising the biggest
                      brands in the world,it’s all connected to our backpack
                      walkers. Hurry up to apply and change the advertising
                      world and get ready to recieve money for your important
                      work.
                    </p>
                  </div>
                  <a
                    className={styles.create_champ_btn}
                    onClick={this.openContactUsModal}
                  >
                    Register a Backpacker
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.99103 15.0087L14.9082 5.09151"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </Col>
              <Col>
                <img src={Backpacker} alt="" className={styles.main_top_img} />
              </Col>
            </Row>
          </Container>
        </section>
        <section className={styles.backpackers_earn_section}>
          <Container>
            <div className="text-center">
              <h4>How can you be a backpack walker?</h4>
            </div>
            <Row className="d-flex justify-content-between w-100 ml-auto mr-auto">
              <Col>
                <div className={styles.become_backpacker_images}>
                  <img src={App} />
                </div>
                <div className={styles.become_backpacker}>
                  <h5>Download ‘Murmur Partner’ App</h5>
                  <p>
                    It take no less than 5 minutes to dowload ‘Murmur Partner’
                    App on Apple Store and Play Market.Via this app,you will be
                    connected to our ads campaigns
                  </p>
                </div>
              </Col>
              <Col>
                {" "}
                <div className={styles.become_backpacker_images}>
                  <img src={Interview} />
                </div>
                <div className={styles.become_backpacker}>
                  <h5>Take part in the interview with Murmur team</h5>
                  <p>
                    Let’s have an introduction/interview with our team. You will
                    all the answers to your questions.
                  </p>
                </div>
              </Col>
              <Col>
                {" "}
                <div className={styles.become_backpacker_images}>
                  <img src={Walk} />
                </div>
                <div className={styles.become_backpacker}>
                  <h5>Time is money</h5>
                  <p>
                    After successful passing of the interview, you will get your
                    backpack with all the necessary instructions. Let’s take
                    these streets by storm
                  </p>
                </div>
              </Col>
            </Row>
            <img src={Headerbg1} alt="" className={styles.header_bg1} />
            <img src={Headerbg2} alt="" className={styles.header_bg2} />
          </Container>
        </section>
        <section className={styles.backpackers_earn_section}>
          <Container>
            <div className="text-center">
              <h4>Our requirements</h4>
            </div>
            <Row className={styles.custom_row}>
              {" "}
              <div className={`${styles.become_backpacker} text-center`}>
                <span>To have a</span>
                <h5 className={styles.condition_h5}>Smart phone</h5>
                <p className={styles.p}>
                  As you will be using your app during walking, you need to have
                  a smart phone and stable internet connection.
                </p>
              </div>
              <div className={`${styles.become_backpacker} text-center`}>
                <span>To be</span>
                <h5 className={styles.condition_h5}>21+</h5>
                <p className={styles.p}>
                  The job of backpack walker can be difficult sometimes, so we
                  hire people who are mentally and physically ready for it.
                </p>
              </div>
              <div className={`${styles.become_backpacker} text-center`}>
                <span>To work at least </span>
                <h5 className={styles.condition_h5}>hours per day</h5>
                <p className={styles.p}>
                  You can choose the shift hours that suits you best. Your
                  payment will be calculated based on these hours.
                </p>
              </div>
              <div className={`${styles.become_backpacker} text-center`}>
                <span>Have a</span>
                <h5 className={styles.condition_h5}>valid ID</h5>
                <p className={styles.p}>
                  Bring with you the ID card in order to be officially
                  registered.
                </p>
              </div>
              <div className={`${styles.become_backpacker} text-center`}>
                <span>Open a </span>
                <h5 className={styles.condition_h5}> bank account</h5>
                <p className={styles.p}>
                  As your payment will be automatically transferred to your bank
                  account, it is required to open the bank account.
                </p>
              </div>
            </Row>
          </Container>
        </section>
        <section className={styles.backpackers_earn_section}>
          <Container>
            <div className="text-center">
              <h4>Statistics</h4>
            </div>
            <Row className={`${styles.custom_row} ${styles.our_statistics}`}>
              {" "}
              <div className={`${styles.become_backpacker} text-center`}>
                <h5 className={styles.pros_in_nmrs}>5K </h5>
                <p className={styles.p}>backpack walkers</p>
              </div>
              <div className={`${styles.become_backpacker} text-center`}>
                <h5 className={styles.pros_in_nmrs}>28.6K</h5>
                <p className={styles.p}>Ad Campaigns</p>
              </div>
              <div className={`${styles.become_backpacker} text-center`}>
                <h5 className={styles.pros_in_nmrs}>Up to $2000</h5>
                <p className={styles.p}>
                  The monthly salary of backpack walkers
                </p>
              </div>
              <div className={`${styles.become_backpacker} text-center`}>
                <h5 className={styles.pros_in_nmrs}>400+</h5>
                <p className={styles.p}>
                  Number of brands and advertisers Murmur is collaborating with
                </p>
              </div>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <div className={styles.backpackers}>
              <img src={Backpackers} alt="" />
              <div className={styles.text}>
                <h3>We believe that you can make money by walking.</h3>
                <p>We believe that you can make money by walking.</p>
                <a
                  className={styles.create_champ_btn}
                  onClick={this.openContactUsModal}
                >
                  Register a Backpacker
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.99103 15.0087L14.9082 5.09151"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </Container>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default BackapckersComponent;
