import React from "react";

import classes from '../../../assets/css/hypeIQ/testimonials.module.scss'
import Avatar from '../../../assets/images/hypeIQ/avatar.png'

class Testimonial extends React.Component {
  render() {
    return (
      <figure className={classes.testimonial_container}>
        <img
          src={Avatar}
          alt="author"
          className={classes.testimonial_author_avatar}
        />
        <footer>
          <cite className={classes.author}>Joe Doe</cite>
          <div>
            <cite className={classes.author_info}>26 years old</cite>
            <cite className={classes.author_info}>68K followers</cite>
          </div>
          <blockquote>
            Id adipiscing eget cursus dictum sed arcu est. Sollicitudin euismod
            tortor id felis facilisi dignissim ridiculus proin. Ipsum vitae nisl
            elementum, scelerisque sed ac. Nunc, quis pretium egestas tortor
            viverra mauris nunc sed.
          </blockquote>
        </footer>
      </figure>
    );
  }
}

export default Testimonial;
