import React from "react";
import { Helmet } from "react-helmet";
import HeroLayoutsTypeThird from "./../layouts/HeroLayouts/HeroLayoutsTypeThird/HeroLayoutsTypeThird";
import { keywords } from "./keywords";
import HeroLayoutsTypeNinth from "./../layouts/HeroLayouts/HeroLayoutsTypeNinth/HeroLayoutsTypeNinth";
import HeroLayoutsTypeSeventh from "./../layouts/HeroLayouts/HeroLayoutsTypeSeventh/HeroLayoutsTypeSeventh";
import HeroLayoutsTypeFifth from "./../layouts/HeroLayouts/HeroLayoutsTypeFifth/HeroLayoutsTypeFifth";
import HeroLayoutsTypeSixth from "./../layouts/HeroLayouts/HeroLayoutsTypeSixth/HeroLayoutsTypeSixth";
import HeroLayoutsTypeSecond from "./../layouts/HeroLayouts/HeroLayoutsTypeSecond/HeroLayoutsTypeSecond";
import HeroLayoutsTypeEighth from "./../layouts/HeroLayouts/HeroLayoutsTypeEighth/HeroLayoutsTypeEighth";
import FeaturesLayoutsTypeEleventh from "./../layouts/FeaturesLayouts/FeaturesLayoutsTypeEleventh/FeaturesLayoutsTypeEleventh";
import FeaturesLayoutsTypeFifth from "./../layouts/FeaturesLayouts/FeaturesLayoutsTypeFifth/FeaturesLayoutsTypeFifth";
import FeaturesLayoutsTypeSixth from "./../layouts/FeaturesLayouts/FeaturesLayoutsTypeSixth/FeaturesLayoutsTypeSixth";
import FootersLayoutsTypeFirst from "./../layouts/FootersLayouts/FootersLayoutsTypeFirst/FootersLayoutsTypeFirst";
import StatsLayoutsTypeFourth from "./../layouts/Stats/StatsLayoutsTypeFourth/StatsLayoutsTypeFourth";

function forAdvertisers() {
  return (
    <div>
      <Helmet>
        <meta
          name="Murmur: Out-of-Home Advertising redefined. Target on streets, retarget via online ads."
          content="Murmur is the first smart billboard advertising solutions allows to target people on streets and retarget them online"
        />
        <meta name="keywords" content={keywords} />
        <meta
          name="description"
          content="Murmur is a new way for brands to connect with their audience in the real world via real-time street advertising on car-top billboards"
        />
        <meta
          property="og:title"
          content="Murmur Advertising| Target audience on digital car-top billboards.Map your audience anywhere they go. "
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content="Murmur | Target audience on digital car-top billboards."
        />
        <meta
          property="og:image"
          content="https://drive.google.com/file/d/10HA1_FFApREduZ6aXuCmXXVk35AcejHn/view?usp=sharing"
        />
        <meta
          property="og:description"
          content="Murmur collects data from outdoor ads and retargets people dynamically online"
        />
        <meta
          property="og:title"
          content="Murmur | Target audience on digital car-top billboards."
        />
        <meta name="og:locality" content="Chicago" />
        <meta property="og:url" content="https://murmurcars.com" />
        <meta name="og:region" content="IL" />
        <meta name="og:postal-code" content="60607" />
        <meta name="og:country-name" content="USA" />
        <meta name="”robots”" content="index, follow" />

        <meta name="twitter:site" content="@murmurcars" />
        <meta
          name="twitter:title"
          content="Murmur | Target audience on digital car-top billboards."
        />

        <meta
          name="twitter:description"
          content="Murmur collects data from outdoor ads and retargets people dynamically online "
        />

        <meta
          name="twitter:image"
          content="https://drive.google.com/file/d/10HA1_FFApREduZ6aXuCmXXVk35AcejHn/view?usp=sharing"
        />

        <meta name="twitter:creator" content="@murmurcars" />
        <title>
          Murmur: Billboard Advertising redefined. Target on streets, retarget
          via online ads.
        </title>
      </Helmet>
      <HeroLayoutsTypeEighth />
      <FeaturesLayoutsTypeFifth />
      <FeaturesLayoutsTypeSixth />

      <FootersLayoutsTypeFirst />
    </div>
  );
}

export default forAdvertisers;
