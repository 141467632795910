import React, { useState } from "react";

import Form from "./SimpleForm/SimpleForm";
import Aside from "./Aside/Aside";

import { Container, Col, Row } from "reactstrap";
import { Google, Twitter } from "../../../components/Icons/Icons";

import ButtonSocial from "../../../components/Button-Social/ButtonSocial";
import Logos from "./Logos/Logos";
import classes from "./FormsLayoutsTypeSecond.module.css";
import { Fade } from "react-awesome-reveal";
import { Alert } from "react-bootstrap";

const features = [
  "30 Non-Commercial Site",
  "Custom Branding",
  "100GB System Storage",
  "Ticket Support",
];

function FormsLayoutsTypeSecond(props) {
  console.log("this is forms Emin ", props);

  const [alertStatus, setAlertStatus] = useState(false);

  const handleAlert = (data) => {
    console.log("Getting data from child to parrent", data);
    setAlertStatus(data);
    console.log(alertStatus);
  };

  return (
    <React.Fragment>
      <section
        className={`section ${classes.form} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        {props.contactUsModal ? (
          <div className={classes.gridWrapper}>
            <Container>
              <Row>
                {!props.contactUsModal ? (
                  <Col lg={{ size: 4, offset: 1 }} md={{ size: 5 }}>
                    <Fade triggerOnce={true}>
                      <Aside
                        title="7 days free trial"
                        subtitle="Starter"
                        description="Go beyond email and customize your workflow"
                        array={features}
                        dark={props.dark ? true : false}
                      >
                        <Logos />
                      </Aside>
                    </Fade>
                  </Col>
                ) : null}

                <Col
                  lg={{ size: 10, offset: 1 }}
                  md={{ size: 7 }}
                  className={classes.rightColumn}
                >
                  {alertStatus ? (
                    <Alert variant="success">
                      We received your message. One of our team member will
                      contact you shortly.
                      {/* <Alert.Link href="#">an example link</Alert.Link>.  */}
                    </Alert>
                  ) : null}
                  <Fade triggerOnce={true}>
                    <Row>
                      <Col className="col-12 d-flex justify-content-between align-items-center">
                        {props.section === "2" ? (
                          <p>
                            We charge $0.03 cents per impression, provide
                            real-time data and analytics, change your Ad content
                            dynamically based on geo-location, population
                            density, demographics, weather.
                          </p>
                        ) : (
                          <h2>
                            Please contact us and we will get back to you in
                            less than 30 min
                          </h2>
                        )}

                        {/* <a
                          href="#"
                          className={`text-right ${classes.loginLink}`}
                        >
                          Login
                        </a> */}
                      </Col>
                      <Col className={`col-12 ${classes.formColumn}`}>
                        <Form
                          dark={props.dark ? true : false}
                          contactUsModal={props.contactUsModal}
                          onClick={handleAlert}
                        />
                      </Col>
                      <Col className="col-12">
                        <p className={`${classes.smallTextCustom} text-center`}>
                          E-mail: info@murmurcars.com | Tel: (847) 350-8685 |
                          1440 W Taylor st, Chicago, IL 60607
                        </p>

                        {/* <div className={`${classes.links} d-flex`}>
                          <ButtonSocial
                            link={true}
                            href={"#"}
                            classes={"primary large outline w-100 p-0"}
                            value={"With Twitter"}
                            icon={<Twitter fill="#345DEE" />}
                          />

                          <ButtonSocial
                            link={true}
                            href={"#"}
                            classes={"primary large outline w-100 p-0"}
                            value={"With Google"}
                            icon={<Google fill="#345DEE" />}
                          />
                        </div> */}
                      </Col>
                    </Row>
                  </Fade>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </section>
    </React.Fragment>
  );
}
export default FormsLayoutsTypeSecond;
