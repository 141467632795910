import React, { Component } from "react";
import { Form, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import FormsLayoutsTypeFourth from "./../../layouts/FormsLayouts/FormsLayoutsTypeFourth/FormsLayoutsTypeFourth";

class AdminLogin extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <FormsLayoutsTypeFourth />
      </React.Fragment>
    );
  }
}

export default AdminLogin;
