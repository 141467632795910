import { Row, Col, Container } from "reactstrap";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";

import Testimonial from "./Testimonial/TestimonialTypeSixth";
import { User } from "../../../components/Icons/Icons";
import { Avatar } from "antd";
import classes from "./TestimonialsLayoutsTypeSixth.module.css";
import { Fade } from "react-awesome-reveal";
import { testimonials } from "./testimonials";

const title = "Testimonials";
const personForView = 4;
class TestimonialsLayoutsTypeSixth extends Component {
  state = {
    renderPerson: personForView,
    person: testimonials,
  };

  renderCards() {
    const card = this.state.person;
    const listItems = card
      .filter((element) => {
        if (element.id <= this.state.renderPerson) {
          return true;
        }
      })
      .map((item, index) => {
        return (
          <Col lg="6" md="6" sm={6} className={classes.item} key={index}>
            <Fade down delay={`${index}1`} triggerOnce={true}>
              <Testimonial
                person={item.name}
                post={item.post}
                descr={item.description}
                link={item.socialLink}
                dark={this.props.dark ? true : false}
              >
                {item.avatar ? (
                  <Avatar size={95} src={item.avatar} />
                ) : (
                  <Avatar
                    size={95}
                    icon={<User fill={"#ffffff"} />}
                    style={{
                      fontSize: 0,
                      backgroundColor: "#345DEE",
                    }}
                  />
                )}
              </Testimonial>
            </Fade>
          </Col>
        );
      });
    return <>{listItems}</>;
  }

  render() {
    return (
      <div>
        <section
          className={`section ${classes.testimonials} ${
            this.props.dark ? `dark ${classes.dark}` : ""
          }`}
        >
          <div className={`grid-wrapper`}>
            <Container>
              <Row>
                <Col lg="8" md="8" className="mx-auto">
                  <Fade top triggerOnce={true}>
                    <h1 className={`title text-center ${classes.title}`}>
                      {title}
                    </h1>
                  </Fade>
                </Col>
              </Row>
              <Row className={classes.testimonialsRow}>
                {this.renderCards()}
              </Row>
            </Container>
          </div>
        </section>
      </div>
    );
  }
}

export default TestimonialsLayoutsTypeSixth;
