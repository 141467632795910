import React, { Component } from "react";
import Slider from "react-slick";
import styles from '../../../assets/css/landing-pages/streetiq/streetLandingSlider.module.css';
import '../../../assets/css/landing-pages/landingPage/index.css';
// imgs
import slider1 from "../../../assets/images/landing-page/streetiq/slider1.jpg";
import Bridge from '../../../assets/images/landing-page/bridge.png'
import PinkLightDIsplay from '../../../assets/images/landing-page/pink-light-display.png'
class Case_Product_Slider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true
    };
    return (
        <div className="slick-slider-platform">
        <section className={styles.street_slider_cover}>
          <Slider {...settings}>
            <div className={styles.street_slider_item}>
                <div className={styles.slider_item_txt}>
                    <p>1. Street adversiting</p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                        <img src={slider1} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
                <div className={styles.slider_item_txt}>
                    <p>2. İndoor Adversiting</p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                    <img src={PinkLightDIsplay} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
                <div className={styles.slider_item_txt}>
                    <p>3. Outdoor adversiting</p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                        <img src={Bridge} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
                <div className={styles.slider_item_txt}>
                    <p>1. Street adversiting</p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                        <img src={slider1} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
                <div className={styles.slider_item_txt}>
                    <p>2. İndoor Adversiting</p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                    <img src={PinkLightDIsplay} alt="" />
                    </a>
                </div>
            </div>
            <div className={styles.street_slider_item}>
                <div className={styles.slider_item_txt}>
                    <p>3. Outdoor adversiting</p>
                </div>
                <div className={styles.slider_item_img}>
                    <a href="#">
                        <img src={Bridge} alt="" />
                    </a>
                </div>
            </div>
          </Slider>
        </section>
      </div>
    );
  }
}

export default Case_Product_Slider;