import React, { Component } from "react";
import Slider from "react-slick";
import styles from "../../../assets/css/landing-pages/streetiq/streetLandingSlider.module.css";
import "../../../assets/css/landing-pages/streetiq/index.css";
// imgs

import AbTesting from '../../../assets/images/landing-page/ab-testing.png'
import AbTestingCreate from '../../../assets/images/landing-page/ab-testing-create.png'
import AbTestingReport from '../../../assets/images/landing-page/ab-testing-report.png'


class IQ_platform_slider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
    };
    return (
      <div className="slick-slider-platform">
      <section className={styles.street_slider_cover}>
        <Slider {...settings}>
          <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding}`}>
              <h4>Activate your ad's preferences </h4>
              <p>Choose the targeting and variations for the ads</p>
            </div>
            <div className={styles.slider_item_img}>
              <img src={AbTesting} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_max_width}`}>
              <h4>Upload your digital ad to the A/B Testing Platform</h4>
              <p>Try different variations of your ad and find out what works best for you </p>
            </div>
            <div className={styles.slider_item_img}>
              <img src={AbTestingCreate} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
          <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding}`}>
              <h4>Check the analytics</h4>
              <p>Check out the weekly analytics of your ad </p>
            </div>
            <div className={styles.slider_item_img}>
              <img src={AbTestingReport} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
          <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding}`}>
              <h4>Activate your ad's preferences </h4>
              <p>Choose the targeting and variations for the ads</p>
            </div>
            <div className={styles.slider_item_img}>
              <img src={AbTesting} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
          <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_max_width}`}>
              <h4>Upload your digital ad to the A/B Testing Platform</h4>
              <p>Try different variations of your ad and find out what works best for you </p>
            </div>
            <div className={styles.slider_item_img}>
              <img src={AbTestingCreate} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
          <div className={`${styles.slider_item_txt} ${styles.slider_item_txt_extra_padding}`}>
              <h4>Check the analytics</h4>
              <p>Check out the weekly analytics of your ad </p>
            </div>
            <div className={styles.slider_item_img}>
              <img src={AbTestingReport} alt="" />
            </div>
          </div>
        </Slider>
      </section>
    </div>
    );
  }
}

export default IQ_platform_slider;