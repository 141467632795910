import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import Nationaly from "../../assets/images/landing-page/mainlanding/nationaly.png";
import HappyBg from "../../assets/images/landing-page/streetiq/happy-bg.png";
import MapImg from "../../assets/images/landing-page/streetiq/map.png";
import Vector1 from "../../assets/images/landing-page/mainlanding/vector1.png";
import Vector2 from "../../assets/images/landing-page/mainlanding/vector2.png";
import Vision_bg1 from "../../assets/images/landing-page/mainlanding/vision-bg1.png";

import MainTop from "../../assets/images/landing-page/mainlanding/main-top.png";
import Headerbg1 from "../../assets/images/landing-page/mainlanding/header-bg1.png";
import Headerbg2 from "../../assets/images/landing-page/mainlanding/header-bg2.png";
import Louis from "../../assets/images/landing-page/streetiq/kapital bank.png";
import Sony from "../../assets/images/landing-page/streetiq/P&G_Logo_SVG.png";
import Facebook from "../../assets/images/landing-page/streetiq/rabita_bank.png";
import Slack from "../../assets/images/landing-page/streetiq/UNDP_logo.png";
import PashaBank from "../../assets/images/landing-page/streetiq/pasha_bank.png";
import MurmurLogo from "../../assets/images/landing-page/streetiq/murmur-logo.svg";
import MobileMenu from "../../assets/images/landing-page/mobil-menu.svg";
import AbTesting from "../../assets/images/landing-page/ab-testing.png";
import AbTestingCreate from "../../assets/images/landing-page/ab-testing-create.png";
import AbTestingReport from "../../assets/images/landing-page/ab-testing-report.png";
import Locations from "../../assets/images/landing-page/mainlanding/locations.svg";
import LocationSmall from "../../assets/images/landing-page/mainlanding/location-small.svg";
// css
import "../../assets/css/landing-pages/mainLanding/index.css";
import styles from "../../assets/css/landing-pages/mainLanding/main.module.css";
import Brand_Slider from "../../components/landing-pages/mainLanding/Brand-slider";
import Success_Stories_Slider from "../../components/landing-pages/mainLanding/Success-stories";
import Ads_Double_Slide1 from "../../components/landing-pages/mainLanding/Ads-Double-Slide1";
import Ads_Double_Slide2 from "../../components/landing-pages/mainLanding/Ads-Double-Slide2";
import System_Works_Slider from "../../components/landing-pages/mainLanding/System-Works-Slider";
import Diagram_Slider from "../../components/landing-pages/mainLanding/Diagram-Slider";

import ContactUsModal from "../../layouts/HeroLayouts/HeroLayoutsTypeTenth/Header/contactUsModal";
import { SlackWebHook } from "../../api";

import Benefits from "../../components/sections/benefits/benefits";
import Testimonials from "../../components/sections/testimonials/testimonials";
import Innovation from "../../components/sections/innovation/innovation";
import GetTailoredMarketingStrategy from "../../components/sections/getTailoredMarketingStrategy/gettailoredmarketingstrategy";
import TransformYourAdvertising from "../../components/sections/transformAdvertising/transformyouradvertising";
import Footer from "../../components/sections/footer/footer";
import { Helmet } from "react-helmet";
import {main_landing_seo_keywords} from "./main-landing-seo-keywords"

// import Available1 from "../../assets/images/landing-page/destination/available1.png";
// import Available2 from "../../assets/images/landing-page/destination/available2.png";
// import Available3 from "../../assets/images/landing-page/destination/available3.png";
// import Available4 from "../../assets/images/landing-page/destination/available4.png";
// import Available5 from "../../assets/images/landing-page/destination/available5.png";
// import Available6 from "../../assets/images/landing-page/destination/available6.png";

let async = null;
class MainLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      slider: {
        slide: "testimonial1",
      },
      contact: {
        fullName: null,
        email: null,
        company: null,
        phone: null,
        message: null,
        companywebsite: null,
      },
      contactModal: false,
    };
    this.handleClick = this.handleClick.bind(this);

    this.content = [
      {
        header: "Real-time Customer Data:",
        paragraph:
          "Locate and display your ads in front of the customers wherever they may be",
      },
      {
        header: "Segment Integration:",
        paragraph:
          "Trigger conversions and re-marketing tags without code changes with the Segment integration feature",
      },

      {
        header: "Dynamically Changing Ad Content:",
        paragraph:
          "Launch dynamic content of the ads that evolves and changes according to viewer`s age, gender, geo-location and etc",
      },

      {
        header: "Personalized Hyper-Targeted Ads:",
        paragraph:
          "Strategically display your ads according to your target audience’s interests, geo-location, time of day, age and more filters",
      },
      {
        header: "Build Better Business Recognition:",
        paragraph:
          "Display your ads where it would be viewed, enjoyed and captured more. Target your customers strategically",
      },

      {
        header: "Strategic Ad Retargeting:",
        paragraph:
          "Acquire higher click through rates by changing the content according to demographics, geo-location, trust and relevancy",
      },
    ];
  }
  handleClick() {
    this.setState({
      active: !this.state.active,
    });
  }
  openContactUsModal = () => {
    this.setState({
      ...this.state,
      contactModal: true,
    });
  };
  switchToCLickedVerticalSlide = (slide) => {
    this.setState({
      ...this.state,
      slider: {
        slide,
      },
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const slide = this.state.slider.slide;
    if (prevState.slider.slide !== slide) {
      if (slide === "testimonial1") {
        async = setTimeout(
          () =>
            this.setState({
              ...this.state,
              slider: { slide: "testimonial2" },
            }),
          4000
        );
      } else if (slide === "testimonial2") {
        async = setTimeout(
          () =>
            this.setState({
              ...this.state,
              slider: { slide: "testimonial3" },
            }),
          4000
        );
      } else {
        async = setTimeout(
          () =>
            this.setState({
              ...this.state,
              slider: { slide: "testimonial1" },
            }),
          4000
        );
      }
    }
  }
  componentWillUnmount() {
    if (async) {
      clearTimeout(async);
    }
    document.body.classList.remove("bg-transparent");
    document.body.classList.add("default");
  }
  componentDidMount() {
    document.body.classList.remove("default");
    document.body.classList.add("bg-transparent");
  }
  handleSubmit = () => {
    const { fullName, email, company, phone, message, companywebsite } =
      this.state.contact;
    axios
      .post(
        SlackWebHook,
        { fullName, email, company, phone, message, companywebsite },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              " GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        }
      )
      .then((res) => {
        window.location.reload();
        // passDataToParent(true);
      })
      .catch((err) => {
        //window.location.reload();
        // passDataToParent(true);
      });
  };
  emailToSend = (e, type) => {
    const value = e.target.value;

    this.setState({
      ...this.state,
      contact: { ...this.state.contact, [type]: value },
    });
  };
  render() {
    const { slide } = this.state.slider;

    return (
      <React.Fragment>
        
        <Helmet>
        <meta name="keywords" content={main_landing_seo_keywords}/>
        
        </Helmet>
        <header className={styles.main_header}>
          <Container className={styles.main_header_contain}>
            <div className={styles.main_header_left}>
              <a href="#" className={styles.logo}>
                <img src={MurmurLogo} alt="" />
              </a>
            </div>
            <div
              className={
                this.state.active
                  ? "main_header_center activeMenu"
                  : "main_header_center"
              }
            >
              <span>
                <Link to="/advertisers">Advertisers</Link>
              </span>
              <span>
                <Link to="/advertise-on-car">Drivers</Link>
              </span>
              <span>
                <a href="https://blog.murmurcars.com">Blog</a>
              </span>
              <span>
                <a href="https://portal.murmurads.com/login">Login</a>
              </span>
            </div>
            <div className={styles.main_header_right}>
              <a
                className={styles.header_contact}
                onClick={this.openContactUsModal}
              >
                <span>Contact us</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                    fill="#7356C0"
                  />
                  <path
                    d="M12.0008 12.87C11.1608 12.87 10.3108 12.61 9.66076 12.08L6.53075 9.58C6.21075 9.32 6.15076 8.85 6.41076 8.53C6.67076 8.21 7.14076 8.15 7.46076 8.41L10.5908 10.91C11.3508 11.52 12.6407 11.52 13.4007 10.91L16.5308 8.41C16.8508 8.15 17.3308 8.2 17.5808 8.53C17.8408 8.85 17.7908 9.33 17.4608 9.58L14.3308 12.08C13.6908 12.61 12.8408 12.87 12.0008 12.87Z"
                    fill="#7356C0"
                  />
                </svg>
              </a>
              <button
                type="button"
                onClick={this.handleClick}
                className={styles.mobile_menu}
              >
                <img src={MobileMenu} alt="" />
              </button>
            </div>
          </Container>
        </header>
        <section className={styles.main_top_section}>
          <Container className={styles.main_top_contain}>
            <div className={styles.main_top_info}>
              <div className={styles.main_top_info_box}>
                <img src={MainTop} alt="" className={styles.main_top_img} />

                <img
                  src={Locations}
                  alt=""
                  className={styles.main_top_img_location}
                />
                <img
                  src={Locations}
                  alt=""
                  className={styles.main_top_img_location}
                />
                <img
                  src={Locations}
                  alt=""
                  className={styles.main_top_img_location}
                />

                <div className={styles.tailored_left}>
                  <h4 className={styles.tailored_h4}>What is Murmur</h4>
                  <p className={styles.tailored_p}>
                    Murmur is the data driven out-of-home advertising platform which uses smart LCD screens on backacks to show ads on streets. Measurable OOH advertising solution.
                  </p>
                  <a
                    className={styles.create_champ_btn}
                    onClick={this.openContactUsModal}
                  >
                    Create Campaign
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.99103 15.0087L14.9082 5.09151"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </Container>
          <img src={Headerbg1} alt="" className={styles.header_bg1} />
          <img src={Headerbg2} alt="" className={styles.header_bg2} />
        </section>
        <section className={styles.street_leading_section}>
          <div className={styles.leading_tt}>
            We work with some of the world’s <strong>leading</strong> companies
          </div>
          <div className={styles.leading_items}>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Sony} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Louis} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Slack} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Facebook} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={PashaBank} alt="" />
              </a>
            </div>
          </div>
        </section>
        <section className={styles.main_diagram}>
          <Container className={styles.diagram_contain}>
            <Diagram_Slider open={this.openContactUsModal} />
          </Container>
        </section>
        <Benefits showShades={true} showComputerVisionPart={true}  content={this.content}/>
        <Testimonials
          slide={slide}
          switchToCLickedVerticalSlide={this.switchToCLickedVerticalSlide}
        />
        <section className={styles.system_works}>
          <h2 className={styles.title_h2}>How our system works?</h2>
          <div className={styles.main_sub_txt}>
            Our screens powered by computer vision and patented sensor
            technology harvest first hand data and with further enrich in
            partnership with global data providers
          </div>
          <System_Works_Slider />
          <img src={Vision_bg1} alt="" className={styles.vision_bg1} />
        </section>
        <Innovation />
        <section className={styles.main_ab_testing}>
          <h2 className={styles.title_h2}>Meet our A/B Testing Platform</h2>
          <div className={styles.main_sub_txt}>
            Do you want to know what type of digital ad can attract more
            customers? With our A/B Testing Platform, you will be able to try
            different types of ads and find what works for your business
          </div>
          <Container className={styles.main_ab_items}>
            <div className={styles.main_ab_item}>
              <Row className={styles.main_ab_row}>
                <Col md="5" xs="12">
                  <div className={styles.main_ab_txt}>
                    <h4>Activate your ad's preferences </h4>
                    <p>Choose the targeting and variations for the ads</p>
                  </div>
                </Col>
                <Col md="7" xs="12">
                  <div className={styles.main_ab_img}>
                    <img src={AbTesting} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className={styles.main_ab_item}>
              <Row className={styles.main_ab_row}>
                <Col md="5" xs="12">
                  <div className={styles.main_ab_txt}>
                    <h4>Upload your digital ad to the A/B Testing Platform </h4>
                    <p>
                      Try different variations of your ad and find out what
                      works best for you
                    </p>
                  </div>
                </Col>
                <Col md="7" xs="12">
                  <div className={styles.main_ab_img}>
                    <img src={AbTestingCreate} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className={styles.main_ab_item}>
              <Row className={styles.main_ab_row}>
                <Col md="5" xs="12">
                  <div className={styles.main_ab_txt}>
                    <h4>Check the analytics</h4>
                    <p>Check out the weekly analytics of your ad</p>
                  </div>
                </Col>
                <Col md="7" xs="12">
                  <div className={styles.main_ab_img}>
                    <img src={AbTestingReport} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <img src={Vector1} alt="" className={styles.vector1_img} />
          <img src={Vector2} alt="" className={styles.vector2_img} />
        </section>
        <section className={styles.happy_street}>
          <img src={HappyBg} alt="" className={styles.happy_bg_img} />
          <Container className={styles.happy_container}>
            <h5 className={styles.happy_h5}>
              What's happening on the street where your ad's located?
            </h5>
            <div className={styles.happy_map}>
              <img src={MapImg} alt="" />
              <img
                src={Locations}
                alt=""
                className={styles.single_img_location}
              />
              <img
                src={LocationSmall}
                alt=""
                className={styles.single_img_location_2}
              />
            </div>
            <div className={styles.happy_blocks}>
              <Row>
                <Col md="3" xs="6" className={styles.happy_block}>
                  <div className={styles.happy_block_inner}>
                    <p>2500</p>
                    <span>People viewing ad</span>
                  </div>
                </Col>
                <Col md="3" xs="6" className={styles.happy_block}>
                  <div className={styles.happy_block_inner}>
                    <p>63%</p>
                    <span>Is male</span>
                  </div>
                </Col>
                <Col md="3" xs="6" className={styles.happy_block}>
                  <div className={styles.happy_block_inner}>
                    <p>16k</p>
                    <span>Daily views</span>
                  </div>
                </Col>
                <Col md="3" xs="6" className={styles.happy_block}>
                  <div className={styles.happy_block_inner}>
                    <p>3</p>
                    <span>Famous locations</span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={styles.happy_demo_txt}>
              Learn what's happening with your ad on the street by receiving
              real time data Go to the Street IQ for more detailed information:
              -it's okay,no change's needed
            </div>
            <div className="text-center">
              <a
                href="https://app.murmurcars.com/register"
                className={styles.view_demo_btn}
              >
                Free Trial
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.99103 15.0087L14.9082 5.09151"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </Container>
        </section>
        <section className={styles.ads_double}>
          <h2>Examples of our ad campaigns</h2>
          <div className={styles.ads_double_txt}>
            Murmur provides services of car-top,back-pack and social media
            advertising for its customers. Among are clients are private
            companies,small and medium sized businesses and individuals
          </div>
          <Container className={styles.ads_double_contain}>
            <Row>
              <Col md="6" xs="12">
                <div className={styles.ads_double_slide}>
                  <p className={styles.ads_double_tt}>Car top Billboard</p>
                  <Ads_Double_Slide1 />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className={styles.ads_double_slide}>
                  <p className={styles.ads_double_tt}>Analytics page</p>
                  <Ads_Double_Slide2 />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={styles.target_local}>
          <h2>Target Locally, Advertise Nationally</h2>
          <div className={styles.target_local_txt}>
            Murmur helps businesses to attract possible customers by placing
            billboard ads locally and advertise it on digital platforms
            nationally
          </div>
          <div className={styles.target_local_img}>
            <img src={Nationaly} alt="" />
          </div>
        </section>
        <section className={styles.success_stories}>
          <h2>Our Success Stories</h2>
          <div className={styles.success_stories_txt}>
            Read about some of the greatest stories of businesses like yours,
            that use Murmur
          </div>
          <Success_Stories_Slider />
        </section>
        <GetTailoredMarketingStrategy emailToSend={this.emailToSend} />
        {/* <section className={styles.main_brand_slider}>
          <h2>Get inspired by the brands that use Murmur</h2>
          <div className={styles.main_slider_txt}>
            Read the success stories of our clients that used Murmur's
            advertising services
          </div>
          <Brand_Slider />
        </section> */}

        <TransformYourAdvertising />
        
        <section className={styles.contact_section}>
          <Container className={styles.contact_container}>
            <div className={styles.contact_cover}>
              <div className={styles.contact_inner}>
                <Row>
                  <Col md="12" xs="12">
                    <div className={styles.contact_left}>
                      <h4>Contact us</h4>
                      <p className={styles.contact_p}>info@murmurcars.com</p>
                      <a
                        onClick={this.openContactUsModal}
                        className={styles.create_champ_btn}
                      >
                        Create Campaign
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133"
                            stroke="#7356C0"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.99103 15.0087L14.9082 5.09151"
                            stroke="#7356C0"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </section>
        <Footer openContactUsModal={this.openContactUsModal} />
        <ContactUsModal
          contactUsModal
          show={this.state.contactModal}
          onHide={() => this.setState({ ...this.state, contactModal: false })}
        />
      </React.Fragment>
    );
  }
}

export default MainLanding;
