import { Row, Col, Container } from "reactstrap";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Portfolio from "./Portfolio/PortfolioTypeFirst";
import placeholder from "../../../img/Video/ad1.jpg";
import placeholder2 from "../../../img/Video/local-business-growth.gif";
import placeholderDark from "../../../img/Video/ad1.jpg";
import classes from "./PortfolioLayoutsTypeFirst.module.css";
import { Fade } from "react-awesome-reveal";

const strong = "Success Stories";
const title =
  "Read about some of the great stories about businesses like yours, that use Murmur";

class PortfolioLayoutsTypeFirst extends Component {
  state = {
    renderPost: 0,
    projects: [
      {
        name:
          "Murmur Helped Life Insurance Agent to Generate More Leads and Grow Sales by 22% Monthly.",
        id: 1,
        link: "https://www.blog.murmurcars.com/insurance-agency-marketing/",
        poster: placeholder,
        latest: true,
      },
      {
        name:
          "How Murmur Helped Local Turkish Coffee Shop To Grow and Win Competition with Local Starbucks.",
        id: 2,
        link:
          "https://www.blog.murmurcars.com/how-to-grow-a-small-business-into-a-large-business/",
        poster: placeholder2,
        latest: true,
      },
    ],
  };

  renderCards() {
    const card = this.state.projects;
    const listItems = card
      .filter((element) => {
        if (element.latest == true) {
          return true;
        }
      })
      .map((item, index) => {
        return (
          <Col lg="6" md="6" className={classes.item} key={index}>
            <Fade up delay={`${index}1`} triggerOnce={true}>
              <Portfolio name={item.name} link={item.link}>
                <>
                  {item.poster ? (
                    <img src={item.poster} alt="Murmur advertising" />
                  ) : (
                    <img
                      src={this.props.dark ? placeholderDark : placeholder}
                      alt=""
                    />
                  )}
                </>
              </Portfolio>
            </Fade>
          </Col>
        );
      });
    return <Row className={classes.portfolio}>{listItems}</Row>;
  }

  render() {
    return (
      <div>
        <section
          className={`section  ${classes.portfolio} ${
            this.props.dark ? `dark ${classes.dark}` : ""
          }`}
        >
          <div className={classes.gridWrapper}>
            <Container>
              <Row>
                <Col lg="8" md="8" className="mx-auto text-center">
                  <Fade top triggerOnce={true}>
                    <h1>
                      <strong style={{ display: "block" }} className="strong">
                        {strong}
                      </strong>
                    </h1>
                  </Fade>
                  <Fade top delay={50} triggerOnce={true}>
                    <h2 className="title">{title}</h2>
                  </Fade>
                </Col>
              </Row>
              {this.renderCards()}
            </Container>
          </div>
        </section>
      </div>
    );
  }
}

export default PortfolioLayoutsTypeFirst;
