import React from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Col } from "reactstrap";
import { EmailIcon, UserIcon, Lock } from "../../../../components/Icons/Icons";
import Button from "../../../../components/Button/Button";
import "antd/dist/antd.css";
import { Checkbox } from "antd";
import classes from "./SimpleForm.module.css";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { useHistory } from "react-router-dom";

const AdminEmail = "eminfaliyev@gmail.com";
const AdminPassword = "Azerics@2016";

const SimpleForm = (props) => {
  const [LoginEmail, setLoginEmail] = React.useState();
  const [LoginPassword, setLoginPassword] = React.useState();
  const history = useHistory();

  const formSubscribe = (id, data) => {
    if (id === "LoginEmail") {
      setLoginEmail(data);
    }
    if (id === "LoginPassword") {
      setLoginPassword(data);
    }
  };

  const handleSubmit = (e) => {
    console.log(LoginEmail.LoginEmail, "And", AdminEmail);
    e.preventDefault();
    if (
      LoginEmail.LoginEmail === AdminEmail &&
      LoginPassword.LoginPassword === AdminPassword
    ) {
      localStorage.setItem("AdminUser", "Admin");
      history.push("/");
    } else {
      alert("Email or Password is incorrect");
    }
  };

  return (
    <React.Fragment>
      <Form className={classes.form} onSubmit={handleSubmit}>
        <Form.Row>
          <CustomInput
            classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
            controlId="FormInputName"
            name="LoginEmail"
            dark={props.dark == true ? true : null}
            placeholder="Username"
            simple={false}
            leftIcon={true}
            icon={<EmailIcon />}
            onClick={formSubscribe}
          />
          <CustomInput
            classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
            controlId="FormInputUserPassword"
            name="LoginPassword"
            dark={props.dark == true ? true : null}
            placeholder="LoginPassword"
            simple={false}
            leftIcon={true}
            icon={<Lock />}
            type="password"
            onClick={formSubscribe}
          />
        </Form.Row>
        {/* <Form.Row>
                    <CustomInput
                        classes={`col-lg-6 col-md-6 col-sm-6 ${classes.p15} ${classes.item} ${props.classes}`}
                        controlId="FormInputUserPassword"
                        name="name"
                        dark={(props.dark == true) ?  true : null}
                        placeholder="Password"
                        simple={false}
                        leftIcon={true}
                        icon={<Lock/>}
                        type="password"
                    />
                    <CustomInput
                        classes={`col-lg-6 col-md-6 col-sm-6 ${classes.p15} ${classes.item} ${props.classes}`}
                        controlId="FormInputUserPasswordConfirm"
                        name="name"
                        dark={(props.dark == true) ?  true : null}
                        placeholder="Confirm"
                        simple={false}
                        leftIcon={true}
                        icon={<Lock/>}
                        type="password"
                    />
                </Form.Row> */}
        {/* <Form.Row>
          <Col className={classes.p20}>
            <Checkbox>
              By creating an account, you agree to the Terms and Conditions and
              Privacy Policy
            </Checkbox>
          </Col>
        </Form.Row> */}
        <Form.Row>
          <Col className={`col-12 ${classes.p20}`}>
            <Button
              type={"submit"}
              classes={"primary large simple w-100"}
              value={"Login"}
            />
          </Col>
        </Form.Row>
      </Form>
    </React.Fragment>
  );
};

export default SimpleForm;
