import React, { Component } from "react";
import Slider from "react-slick";
import styles from "../../../assets/css/landing-pages/streetiq/streetLandingSlider.module.css";
import "../../../assets/css/landing-pages/streetiq/index.css";
// imgs

import slider2 from "../../../assets/images/landing-page/IMG_3627.jpg";
import slider3 from "../../../assets/images/landing-page/IMG_3600.jpg";
import SilverCar from '../../../assets/images/landing-page/IMG_3559.jpg'

class Our_data_slider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
    };
    return (
      <div className="slick-slider-platform">
      <section className={styles.street_slider_cover}>
        <Slider {...settings}>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={slider3} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={SilverCar} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={slider2} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={slider3} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={SilverCar} alt="" />
            </div>
          </div>
          <div className={styles.street_slider_item}>
            <div className={styles.slider_item_img}>
              <img src={slider2} alt="" />
            </div>
          </div>
        </Slider>
      </section>
    </div>
    );
  }
}

export default Our_data_slider;