

import React, { Component }  from 'react';
import "../../assets/css/landing-pages/mainLanding/index.css";
import styles from "../../assets/css/landing-pages/mainLanding/main.module.css";
import Louis from "../../assets/images/landing-page/streetiq/kapital bank.png";
import Sony from "../../assets/images/landing-page/streetiq/P&G_Logo_SVG.png";
import Facebook from "../../assets/images/landing-page/streetiq/rabita_bank.png";
import Slack from "../../assets/images/landing-page/streetiq/UNDP_logo.png";
import PashaBank from "../../assets/images/landing-page/streetiq/pasha_bank.png";



function Customers (){
    return (
        <section className={styles.street_leading_section}>
          <div className={styles.leading_tt}>
            We work with some of the world’s <strong>leading</strong> companies
          </div>
          <div className={styles.leading_items}>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Sony} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Louis} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Slack} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={Facebook} alt="" />
              </a>
            </div>
            <div className={styles.leading_item}>
              <a href="https://www.murmurcars.com/">
                <img src={PashaBank} alt="" />
              </a>
            </div>
          </div>
        </section>
    )
}

 export default Customers