import React from "react";
import Logos from "./Logos/Logos";

import { Container, Col, Row } from "reactstrap";
import Button from "../../../components/Button/Button";
import mockup from "../../../img/IpadPro.png";
import mockupDark from "../../../img/IpadPro-dark.png";
import Video from "../../../img/Video/grow business.jpg";
import Divider from "../../../components/Divider/Divider";
import { Fade } from "react-awesome-reveal";
import classes from "./FeaturesLayoutsTypeFifteen.module.css";

const title = "Murmur helps small and medium sized businesses to grow";
const description =
  //"Real-time street level data helps your find where your users are, target them instantaneously via car-tom billboards, engage with viewers and collect data like demographics, geo-location,mac-address,ip and more";
  "Mobile car-top billboard is a new advertising channel which reaches more people on streets and roads. Murmur will show your Ads in front of people based on their gender, age, interest, day of the time, weather, geo-location. Target different people and show them personalized Ads. Murmur uses behavioural marketing to deliver your ads and get more engagements, web-site and retails store visits, app downloads.";

function FeaturesLayoutsTypeFifteen(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.features} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg={6} md={6} className={classes.leftColumn}>
                <div className="position-relative">
                  <Fade triggerOnce={true}>
                    {props.dark === true ? (
                      <>
                        <img
                          className="position-absolute"
                          src={Video}
                          width={782}
                          height={599}
                          alt=""
                          copy="1"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="position-absolute"
                          src={props.section === "1" ? Video : mockupDark}
                          width={782}
                          height={599}
                          alt=""
                          copy="1"
                        />
                      </>
                    )}
                  </Fade>
                </div>
              </Col>

              <Col lg={6} md={6} className={classes.rightColumn}>
                <Row xs={1}>
                  <Col className={`${classes.headerColumn} ${classes.col}`}>
                    <Fade down triggerOnce={true}>
                      <h1 className={classes.title}>{title}</h1>
                    </Fade>
                  </Col>
                  <Col
                    className={`${classes.descriptionColumn} ${classes.col}`}
                  >
                    <Fade down delay={100} triggerOnce={true}>
                      <p className={`normal-text ${classes.description}`}>
                        {description}
                      </p>
                    </Fade>
                    <Fade down delay={100} triggerOnce={true}>
                      <div style={{ height: 10 }}></div>
                      <h3 className={classes.title}>
                        Use Murmur to boost your business growth.
                      </h3>
                    </Fade>
                  </Col>
                  <Col className={`${classes.linkColumn} ${classes.col}`}>
                    <Fade down delay={150} triggerOnce={true}>
                      <Button
                        target="blank"
                        link={true}
                        href={"https://calendly.com/eminfaliyev91/30min"}
                        classes={`primary large simple ${classes.button}`}
                        value={"Schedule a Demo"}
                      />
                    </Fade>
                  </Col>
                  <Col className={classes.col}>
                    <Fade down delay={200} triggerOnce={true}>
                      <div className={classes.divider}>
                        <Divider />
                      </div>
                    </Fade>
                  </Col>
                  <Col className={`${classes.logosColumn} ${classes.col}`}>
                    <Fade down delay={250} triggerOnce={true}>
                      {/* <Logos /> */}
                    </Fade>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FeaturesLayoutsTypeFifteen;
