import React from "react";
import { Alert, Form, FormControl, InputGroup } from "react-bootstrap";
import { Col } from "reactstrap";
import {
  EmailIcon,
  UserIcon,
  Lock,
  Phone,
  Document,
  Home,
  Network,
} from "../../../../components/Icons/Icons";
import Button from "../../../../components/Button/Button";
import "antd/dist/antd.css";
import { Checkbox } from "antd";
import classes from "./SimpleForm.module.css";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import axios from "axios";
import { SlackWebHook } from "../../../../api";

const SimpleForm = (props) => {
  const [fullName, setFullName] = React.useState();
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [message, setMessage] = React.useState();
  const [company, setCompany] = React.useState();
  const [companywebsite, setCompanyWebSite] = React.useState();

  const formSubscribe = (id, data) => {
    if (id === "fullName") {
      setFullName(data);
    }
    if (id === "phone") {
      setPhone(data);
    }
    if (id === "email") {
      setEmail(data);
    }
    if (id === "message") {
      setMessage(data);
    }
    if (id === "company") {
      setCompany(data);
    }
    if (id === "companywebsite") {
      setCompanyWebSite(data);
    }
  };

  // const blocks = [
  //   {
  //     type: "section",
  //     text: {
  //       type: "mrkdwn",
  //       text: `Customer ${JSON.stringify(
  //         fullName
  //       )} request Murmur to contact him`,
  //     },
  //   },
  //   {
  //     type: "section",
  //     text: {
  //       type: "mrkdwn",
  //       text: ` Email: ${JSON.stringify(email)} `,
  //     },
  //   },
  //   {
  //     type: "section",
  //     text: {
  //       type: "mrkdwn",
  //       text: ` Phone: ${JSON.stringify(phone)} `,
  //     },
  //   },
  //   {
  //     type: "section",
  //     text: {
  //       type: "mrkdwn",
  //       text: ` Company: ${JSON.stringify(company)} `,
  //     },
  //   },
  //   {
  //     type: "section",
  //     text: {
  //       type: "mrkdwn",
  //       text: ` Message: ${JSON.stringify(message)} `,
  //     },
  //   },
  // ];

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        SlackWebHook,
        { fullName, email, company, phone, message, companywebsite },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              " GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        }
      )
      .then((res) => {
        window.location.reload();
        passDataToParent(true);
      })
      .catch((err) => {
        //window.location.reload();
        console.log({ err });
        passDataToParent(true);
      });
  };

  const passDataToParent = () => {
    props.onClick(true);
  };
 
  return (
    <React.Fragment>
      {props.contactUsModal ? (
        <Form className={classes.form} onSubmit={handleSubmit}>
          <Form.Row>
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputName"
              name="fullName"
              dark={props.dark == true ? true : null}
              placeholder="Full Name"
              simple={false}
              leftIconn={true}
              icon={<UserIcon />}
              onClick={formSubscribe}
            />
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputEmail"
              dark={props.dark == true ? true : null}
              placeholder="Email"
              name="email"
              simple={false}
              leftIconn={true}
              icon={<EmailIcon />}
              type="email"
              onClick={formSubscribe}
            />
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputPhoneNumber"
              dark={props.dark == true ? true : null}
              placeholder="Phone Number"
              name="phone"
              simple={false}
              leftIconn={true}
              icon={<Phone />}
              type="text"
              onClick={formSubscribe}
            />
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputCompany"
              dark={props.dark == true ? true : null}
              placeholder="Company Name"
              name="company"
              simple={false}
              leftIconn={true}
              icon={<Home />}
              type="text"
              onClick={formSubscribe}
            />
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputUserPassword"
              dark={props.dark == true ? true : null}
              placeholder="Company Web site"
              name="companywebsite"
              simple={false}
              leftIcon={true}
              icon={<Network />}
              type="text"
              onClick={formSubscribe}
            />
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputMessage"
              dark={props.dark == true ? true : null}
              placeholder="Message"
              name="message"
              simple={false}
              leftIconn={true}
              icon={<Document />}
              type="text"
              onClick={formSubscribe}
            />
          </Form.Row>
          <Form.Row>
            <Col className={classes.p20}>
              <Button
                type={"submit"}
                classes={"primary large simple w-100"}
                value={"Contact"}
              />
            </Col>
          </Form.Row>
        </Form>
      ) : (
        <Form className={classes.form}>
          <Form.Row>
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputName"
              name="name"
              dark={props.dark == true ? true : null}
              placeholder="Username"
              simple={false}
              leftIcon={true}
              icon={<UserIcon />}
            />
            <CustomInput
              classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputUserPassword"
              dark={props.dark == true ? true : null}
              placeholder="Email"
              name="name"
              simple={false}
              leftIcon={true}
              icon={<EmailIcon />}
              type="email"
            />
          </Form.Row>
          <Form.Row>
            <CustomInput
              classes={`col-lg-6 col-md-6 col-sm-6 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputUserPassword"
              name="name"
              dark={props.dark == true ? true : null}
              placeholder="Password"
              simple={false}
              leftIcon={true}
              icon={<Lock />}
              type="password"
            />
            <CustomInput
              classes={`col-lg-6 col-md-6 col-sm-6 ${classes.p15} ${classes.item} ${props.classes}`}
              controlId="FormInputUserPasswordConfirm"
              name="name"
              dark={props.dark == true ? true : null}
              placeholder="Confirm"
              simple={false}
              leftIcon={true}
              icon={<Lock />}
              type="password"
            />
          </Form.Row>
          <Form.Row>
            <Col className={classes.p20}>
              <Checkbox>
                By creating an account, you agree to the Terms and Conditions
                and Privacy Policy
              </Checkbox>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col className={classes.p20}>
              <Button
                type={"submit"}
                classes={"primary large simple w-100"}
                value={"Create an account"}
              />
            </Col>
          </Form.Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default SimpleForm;
