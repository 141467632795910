import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import PG from "../../assets/images/hypeIQ/P&G.svg";
import Nestle from "../../assets/images/hypeIQ/Nestle.svg";
import Kapital from "../../assets/images/hypeIQ/Kapital.svg";
import Sirab from "../../assets/images/hypeIQ/Sirab.svg";
import Vector902 from "../../assets/images/Surveys/Vector902.png";
import Vector903 from "../../assets/images/Surveys/Vector903.png";
// css
import "../../assets/css/landing-pages/mainLanding/index.css";
import styles from "../../assets/css/surveys/index.module.scss";

import ContactUsModal from "../../layouts/HeroLayouts/HeroLayoutsTypeTenth/Header/contactUsModal";
import { SlackWebHook } from "../../api";
import MurmurLogo from "../../assets/images/landing-page/streetiq/brandIQ.png";

import Footer from "../../components/sections/footer/footer";
import Header from "../../components/sections/surveys-header";
import GetTailoredMarketingStrategy from "../../components/sections/getTailoredMarketingStrategy/gettailoredmarketingstrategy";
import HowWeHelpBrands from "../../components/sections/howWeHelpBrands/HowWeHelpBrands2";

class Surveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      contact: {
        fullName: null,
        email: null,
        company: null,
        phone: null,
        message: null,
        companywebsite: null,
      },
      viewport: {
        width: window.innerWidth,
      },
      contactModal: false,
    };
    this.handleClick = this.handleClick.bind(this);

    this.content = [
      {
        header: "Quick application process:",
        paragraph:
          "The clients can fill out a 1-page application form within 5-6 minutes",
      },
      {
        header: "Attracting more potential clients to the brand:",
        paragraph:
          "After receiving the form, Hype IQ team starts o work on building a buzz strategy for the clients",
      },
      {
        header: "Creating a live hype around the product:",
        paragraph:
          "Via Hype IQ, our clients will be able to see how everyone in their area about their brand in no time",
      },
      {
        header: "Building strong awareness around the brand:",
        paragraph:
          "Via Hype IQ, your brand will be introduced to the public as accurately as possible",
      },
      {
        header: "Your brand will stand out:",
        paragraph:
          "In the ever-changing world market, Hype IQ will make your brand’s representation authentic from others",
      },
      {
        header: "Setting up a strong social media presence:",
        paragraph:
          "Via Hype IQ, a strong social media presence will be prepared for the clients",
      },
    ];
  }
  handleClick() {
    this.setState({
      active: !this.state.active,
    });
  }
  openContactUsModal = () => {
    this.setState({
      ...this.state,
      contactModal: true,
    });
  };
  switchToCLickedVerticalSlide = (slide) => {
    this.setState({
      ...this.state,
      slider: {
        slide,
      },
    });
  };

  updateSize = () => {
    const currentWidth = window.innerWidth;

    this.setState({
      ...this.state,
      viewport: {
        width: currentWidth,
      },
    });
  };

  componentWillUnmount() {
    document.body.classList.remove("bg-transparent");
    document.body.classList.add("default");
    window.removeEventListener("resize", this.updateSize);
  }
  componentDidMount() {
    document.body.classList.remove("default");
    document.body.classList.add("bg-transparent");
    window.addEventListener("resize", this.updateSize);
  }
  handleSubmit = () => {
    const { fullName, email, company, phone, message, companywebsite } =
      this.state.contact;
    axios
      .post(
        SlackWebHook,
        { fullName, email, company, phone, message, companywebsite },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              " GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        }
      )
      .then((res) => {
        window.location.reload();
        // passDataToParent(true);
      })
      .catch((err) => {
        //window.location.reload();
        // passDataToParent(true);
      });
  };
  emailToSend = (e, type) => {
    const value = e.target.value;

    this.setState({
      ...this.state,
      contact: { ...this.state.contact, [type]: value },
    });
  };
  render() {
    const { viewport } = this.state;
    const { width } = viewport;
    return (
      <div className={styles.brand_healph_cont}>
        
            <Header />
        <main>
          <Container>
            <header className={styles.background}>
              <div className={styles.foreground}>
                <div className={styles.text}>
                  <h1>Smart Tool Leading to the Greatest Marketing Results</h1>
                  <p>
                    Brand IQ – is the unique marketing tool providing accurate
                    insights and opinions from consumers
                  </p>
                </div>
                <div className={styles.image} />
              </div>
            </header>
            <section className={styles.survey_app}>
              <h2>Advanced Technology Relevant to Your Brand Tracking Needs</h2>
              <p className={styles.header_paragraph}>
                Leading platform to calculate: Brand Awareness. Brand Loyalty.
                Brand Association
              </p>
              <Row className="mt-5">
                <Col className={styles.app_info}>
                  <h3>Tailor-made Insights</h3>
                  <p>
                    Brand IQ helps you to find out more about your target
                    audience's preferences via online surveys and attract new
                    loyal customers
                  </p>
                </Col>
                <Col className={styles.app_preview} />
              </Row>
              <Row>
                <Col className={styles.app_preview} />
                <Col className={styles.app_info}>
                  <h3>Simple approach to data</h3>
                  <p>
                    Our user-friendly dashboard provides easy access to brand
                    survey's weekly analytics and enables you effortlessly
                    prepare reports and run a viral ad campaign.
                  </p>
                </Col>
              </Row>
            </section>
            <section className={styles.survey_mobile}>
              <h2>How can Brand IQ improve your financial wellbeing?</h2>
              <p className={styles.header_paragraph}>
                We deeply cherish the impact of daily survey takers and give
                them an opportunity to earn money per each participation.
              </p>
              <Row className={styles.follow_steps}>
                <Col>
                  <div className={styles.screen} />
                  <div>
                    <h3>Get survey's Notifications via App</h3>
                    <p>
                      Download 'Murmur Surveys' app via Play Market and App
                      Store and get ready to take part in surveys every
                      Wednesday
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className={styles.screen} />
                  <div>
                    <h3>Answer Questions Right Away</h3>
                    <p>
                      Our surveys are fun and engaging and most importantly
                      there are only a few questions, so you don't need to spend
                      hours to fill them up.
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className={styles.screen} />
                  <div>
                    <h3>Receive money to your bank account</h3>
                    <p>
                      The payment for each survey that you've participated in is
                      being sent to your bank account
                    </p>
                  </div>
                </Col>
              </Row>
            </section>
            <section className={styles.brand_awarness_sec}>
              <h2>
                Find Out Customers' Thoughts and Feelings About Your Brand
              </h2>
              <Row className={styles.brand_awarness_row}>
                <Col>
                  <div className={styles.brand_healph} />
                  <div>
                    <strong>56%</strong>
                    <p>of general population in US are aware of your brand</p>
                  </div>
                </Col>
                <Col>
                  <h3>How users feels</h3>
                  <p>
                    There's nothing more important that researching the feedback
                    from the clients about your brand. Brand IQ makes this
                    process interesting and insightful. Brands have opportunity
                    to upload several surveys and maintain the number of
                    respondents that get these surveys, so they can see broad
                    range of reactions from the users and with that, make they
                    brand as successful as possible.
                  </p>
                </Col>
              </Row>
            </section>
            <HowWeHelpBrands />
            <section className={`${styles.survey_app} ${styles.customer_app}`}>
              <Row className="mb-3">
                <Col className={styles.app_info}>
                  <h3 className={`text-right ${styles.header}`}>
                    Measure Brand Awareness
                  </h3>
                  <p className="text-right">
                    Measure brand awareness and understand how your marketing
                    campaigns are impacting your target audience.
                  </p>
                </Col>
                <Col className={styles.app_preview} />
              </Row>
              <div className="d-flex justify-content-center">
                <img src={Vector902} alt="" />
              </div>
              <Row className="mb-3">
                <Col className={styles.app_preview} />
                <Col className={styles.app_info}>
                  <h3 className={`text-left ${styles.header}`}>
                    See How Your Campaigns Increase Brand Awareness
                  </h3>
                  <p className="text-left">
                    Track specific campaigns over time to prove campaign impact.
                    Add competitor tracking to monitor the performance of
                    competing brands.
                  </p>
                </Col>
              </Row>
              <div className="d-flex justify-content-center">
                <img src={Vector903} alt="" />
              </div>
              <Row>
                <Col className={styles.app_info}>
                  <h3 className={`text-right ${styles.header}`}>
                    Insightful Data{" "}
                  </h3>
                  <p className="text-right">
                    As Brand IQ is highly optimized survey app, our users can
                    receive real-time data right on their device. This specific
                    capability gives you access to get to more respondents in
                    faster time frame.
                  </p>
                </Col>
                <Col className={styles.app_preview} />
              </Row>
            </section>
            <section
              className={`${styles.categories_section} ${styles.marginBTN_zero}`}
            >
              <div className={styles.contact_cover}>
                <div className={styles.contact_inner}>
                  <Row>
                    <Col md="12" xs="12">
                      <div className={styles.contact_left}>
                        <h3> Engaging answers</h3>
                        <p className={styles.contact_p}>
                          {" "}
                          Unlike other survey platforms, Brand IQ focuses on
                          bringing the real-time data provided by the
                          respondents. That means, the answers to surveys'
                          questions are interesting and detailed. By putting
                          their survey's questions to Brand IQ, brands can be
                          sure that the answers to it will not just 'Yes/No'.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={styles.dashboard} />
            </section>
            <section
              className={`${styles.categories_section} ${styles.ai_section}  ${styles.marginBTN_zero}`}
            >
              <div className={styles.contact_cover}>
                <div className={styles.contact_inner}>
                  <Row>
                    <Col md="12" xs="12">
                      <div className={styles.contact_left}>
                        <h3>A Must for Marketing Research</h3>
                        <p className={styles.contact_p}>
                          {" "}
                          Brand IQ platform is a reliable daily source of
                          information for the marketers, due to its design,
                          user- friendly dashboard and concise answers to the
                          survey's questions.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            <section
              className={`${styles.categories_section} ${styles.marginBTN_zero}`}
            >
              <div className={styles.contact_cover}>
                <div className={styles.contact_inner}>
                  <Row>
                    <Col md="12" xs="12">
                      <div className={styles.contact_left}>
                        <h3>
                          {" "}
                          Responders with the broad range of occupations and
                          bakgrounds
                        </h3>
                        <p className={styles.contact_p}>
                          {" "}
                          Getting feedbacks from the targeted customers has
                          never been so smooth. Brand IQ team can help you with
                          creating surveys for responders with the different
                          occupations and backgrounds and get comprehensive
                          data, before launching your product.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={styles.categories} />
                <div className={styles.categories} />
                <div className={styles.categories} />
              </div>
            </section>

            <section>
              <div className={styles.brands}>
                <h2>List of our customers</h2>
                <ul>
                  <li>
                    <img src={PG} alt="brands" />
                  </li>
                  <li>
                    <img src={Nestle} alt="brands" />
                  </li>
                  <li>
                    <img src={Kapital} alt="brands" />
                  </li>
                  <li>
                    <img src={Sirab} alt="brands" />
                  </li>
                </ul>
              </div>
            </section>
            <GetTailoredMarketingStrategy />
            <section
              className={`${styles.contact_section} ${styles.marginBTN_zero}`}
            >
              <div className={styles.contact_cover}>
                <div className={styles.contact_inner}>
                  <Row>
                    <Col md="12" xs="12">
                      <div className={styles.contact_left}>
                        <h4> Create Survey</h4>
                        <a
                          onClick={this.openContactUsModal}
                          className={styles.create_champ_btn}
                        >
                          Create Survey
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.0084 12.1449L15.0084 4.99133L7.85486 4.99133"
                              stroke="#7356C0"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.99103 15.0087L14.9082 5.09151"
                              stroke="#7356C0"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className={styles.social_media} />
            </section>
          </Container>
        </main>
        <Footer openContactUsModal={this.openContactUsModal} />
        <ContactUsModal
          contactUsModal
          show={this.state.contactModal}
          onHide={() => this.setState({ ...this.state, contactModal: false })}
        />
      </div>
    );
  }
}

export default Surveys;
